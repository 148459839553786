import React, { useContext, useState } from 'react'
import CartCtx from '../CartCtx'
import CartItem from './CartItem'
import { isIterableArray } from '../block-slider/utils'
import { Alert, Button, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import SliderFetchWrapper from '../block-slider/SliderFetchWrapper'
import axios from 'axios'
import { useEffect } from 'react'
import AlertCart from './AlertCart'

const PageCart = () => {

    const { cart, removeProduct, getTotalSumm, getTotalWithoutSale, getTotalCount } = useContext(CartCtx)

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([])
    const [textAlert, setTextAlert] = useState(null);

    useEffect(() => {
        if (isIterableArray(cart)) getProducts()
        else setIsLoading(false)
        getTexts()
    }, [cart])


    const getProducts = () => {
        setIsLoading(true)
        const ids = cart.map(i => i.id)
        axios.post(`${process.env.REACT_APP_API_BASE}/products/products-for-cart`, { ids })
            .then(response => {
                console.log(response)
                if (isIterableArray(response.data.data)) {
                    // const res = response.data.data.map(prod => ({
                    //     ...prod,
                    //     count_cart: cart.find(i => i.id == prod.id).count_cart
                    // }))
                    
                    const res = [
                        {
                          "id": 201561,
                          "articule": "10125073",
                          "name": "Шапка дет. 423559аш (чёрный; )",
                          "name_slug": "Shapka-det-423559ash-chernyi--22287734",
                          "photo_url": "10125073.jpg",
                          "count": 1,
                          "price": 738,
                          "discount_price": null,
                          "stock": {
                            "id": 87,
                            "name": "ВАША ВЫГОДА!",
                            "name_slug": "vasha-vygoda",
                            "block_array": "77",
                            "newspaper_id": 35,
                            "type": 1,
                            "text": null,
                            "bottom_description": null,
                            "background_url": null,
                            "show_sidebar": 1,
                            "show_count": 1,
                            "show_search": 1,
                            "category_main": null,
                            "meta_tags": "{\"seo_title\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Макеевка, Донецк, Енакиево, Мариуполь.\", \"robots_index\": \"yes\", \"robots_follow\": \"yes\", \"meta_discription\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Скидки до -70% бытовую технику, сантехнику, мебель, строительство и ремонт, спорттовары, автотовары, посуду, текстиль, подарки. Акция проходит в Макеевке, Донецке, Енакиево, Мариуполе в Торговых центрах \\\"Галактика\\\". \"}",
                            "active": 1,
                            "discount_percent": null,
                            "date_start": "2024-11-01T14:34:00.000Z",
                            "date_end": "2025-01-14T18:00:00.000Z",
                            "text_end": null,
                            "badge_id": 262,
                            "badge_array": "194, 262",
                            "image_url": "Ваша-Выгода-в-ТЦ-Галактика.jpg",
                            "button": null,
                            "text_for_modal": null,
                            "show_day": 1,
                            "order_sidebar": 1,
                            "show_baner_end": 1,
                            "comment": "<br>",
                            "info": null,
                            "manufacturer_id": null,
                            "created_at": "2024-11-29 17:36:31",
                            "updated_at": "2024-12-20 09:35:41",
                            "condition": null
                          },
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 738,
                              "discountPrice": 516.6,
                              "discount": 30
                            },
                            "bages": [
                              {
                                "bage": {
                                  "id": 262,
                                  "text": null,
                                  "text_color": null,
                                  "background_url": "b_262.png",
                                  "background_RGBA": null,
                                  "badge_type": 1
                                }
                              },
                              {
                                "bage": {
                                  "id": 194,
                                  "text": "Скидка –",
                                  "text_color": "#FFFFFF",
                                  "background_url": null,
                                  "background_RGBA": "#FF0000",
                                  "badge_type": 3
                                }
                              }
                            ],
                            "stockProduct": {
                              "id": 87,
                              "name": "ВАША ВЫГОДА!",
                              "name_slug": "vasha-vygoda",
                              "block_array": "77",
                              "newspaper_id": 35,
                              "type": 1,
                              "text": null,
                              "bottom_description": null,
                              "background_url": null,
                              "show_sidebar": 1,
                              "show_count": 1,
                              "show_search": 1,
                              "category_main": null,
                              "meta_tags": "{\"seo_title\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Макеевка, Донецк, Енакиево, Мариуполь.\", \"robots_index\": \"yes\", \"robots_follow\": \"yes\", \"meta_discription\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Скидки до -70% бытовую технику, сантехнику, мебель, строительство и ремонт, спорттовары, автотовары, посуду, текстиль, подарки. Акция проходит в Макеевке, Донецке, Енакиево, Мариуполе в Торговых центрах \\\"Галактика\\\". \"}",
                              "active": 1,
                              "discount_percent": null,
                              "date_start": "2024-11-01T14:34:00.000Z",
                              "date_end": "2025-01-14T18:00:00.000Z",
                              "text_end": null,
                              "badge_id": 262,
                              "badge_array": "194, 262",
                              "image_url": "Ваша-Выгода-в-ТЦ-Галактика.jpg",
                              "button": null,
                              "text_for_modal": null,
                              "show_day": 1,
                              "order_sidebar": 1,
                              "show_baner_end": 1,
                              "comment": "<br>",
                              "info": null,
                              "manufacturer_id": null,
                              "created_at": "2024-11-29 17:36:31",
                              "updated_at": "2024-12-20 09:35:41",
                              "condition": null
                            }
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 738,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 738,
                                  "discountPrice": 516.6,
                                  "discount": 30
                                },
                                "bages": [
                                  {
                                    "bage": {
                                      "id": 262,
                                      "text": null,
                                      "text_color": null,
                                      "background_url": "b_262.png",
                                      "background_RGBA": null,
                                      "badge_type": 1
                                    }
                                  },
                                  {
                                    "bage": {
                                      "id": 194,
                                      "text": "Скидка –",
                                      "text_color": "#FFFFFF",
                                      "background_url": null,
                                      "background_RGBA": "#FF0000",
                                      "badge_type": 3
                                    }
                                  }
                                ],
                                "stockProduct": {
                                  "id": 87,
                                  "name": "ВАША ВЫГОДА!",
                                  "name_slug": "vasha-vygoda",
                                  "block_array": "77",
                                  "newspaper_id": 35,
                                  "type": 1,
                                  "text": null,
                                  "bottom_description": null,
                                  "background_url": null,
                                  "show_sidebar": 1,
                                  "show_count": 1,
                                  "show_search": 1,
                                  "category_main": null,
                                  "meta_tags": "{\"seo_title\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Макеевка, Донецк, Енакиево, Мариуполь.\", \"robots_index\": \"yes\", \"robots_follow\": \"yes\", \"meta_discription\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Скидки до -70% бытовую технику, сантехнику, мебель, строительство и ремонт, спорттовары, автотовары, посуду, текстиль, подарки. Акция проходит в Макеевке, Донецке, Енакиево, Мариуполе в Торговых центрах \\\"Галактика\\\". \"}",
                                  "active": 1,
                                  "discount_percent": null,
                                  "date_start": "2024-11-01T14:34:00.000Z",
                                  "date_end": "2025-01-14T18:00:00.000Z",
                                  "text_end": null,
                                  "badge_id": 262,
                                  "badge_array": "194, 262",
                                  "image_url": "Ваша-Выгода-в-ТЦ-Галактика.jpg",
                                  "button": null,
                                  "text_for_modal": null,
                                  "show_day": 1,
                                  "order_sidebar": 1,
                                  "show_baner_end": 1,
                                  "comment": "<br>",
                                  "info": null,
                                  "manufacturer_id": null,
                                  "created_at": "2024-11-29 17:36:31",
                                  "updated_at": "2024-12-20 09:35:41",
                                  "condition": null
                                }
                              }
                            },
                            {
                              "id": 4,
                              "price": 738,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 738,
                                  "discountPrice": 516.6,
                                  "discount": 30
                                },
                                "bages": [
                                  {
                                    "bage": {
                                      "id": 262,
                                      "text": null,
                                      "text_color": null,
                                      "background_url": "b_262.png",
                                      "background_RGBA": null,
                                      "badge_type": 1
                                    }
                                  },
                                  {
                                    "bage": {
                                      "id": 194,
                                      "text": "Скидка –",
                                      "text_color": "#FFFFFF",
                                      "background_url": null,
                                      "background_RGBA": "#FF0000",
                                      "badge_type": 3
                                    }
                                  }
                                ],
                                "stockProduct": {
                                  "id": 87,
                                  "name": "ВАША ВЫГОДА!",
                                  "name_slug": "vasha-vygoda",
                                  "block_array": "77",
                                  "newspaper_id": 35,
                                  "type": 1,
                                  "text": null,
                                  "bottom_description": null,
                                  "background_url": null,
                                  "show_sidebar": 1,
                                  "show_count": 1,
                                  "show_search": 1,
                                  "category_main": null,
                                  "meta_tags": "{\"seo_title\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Макеевка, Донецк, Енакиево, Мариуполь.\", \"robots_index\": \"yes\", \"robots_follow\": \"yes\", \"meta_discription\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Скидки до -70% бытовую технику, сантехнику, мебель, строительство и ремонт, спорттовары, автотовары, посуду, текстиль, подарки. Акция проходит в Макеевке, Донецке, Енакиево, Мариуполе в Торговых центрах \\\"Галактика\\\". \"}",
                                  "active": 1,
                                  "discount_percent": null,
                                  "date_start": "2024-11-01T14:34:00.000Z",
                                  "date_end": "2025-01-14T18:00:00.000Z",
                                  "text_end": null,
                                  "badge_id": 262,
                                  "badge_array": "194, 262",
                                  "image_url": "Ваша-Выгода-в-ТЦ-Галактика.jpg",
                                  "button": null,
                                  "text_for_modal": null,
                                  "show_day": 1,
                                  "order_sidebar": 1,
                                  "show_baner_end": 1,
                                  "comment": "<br>",
                                  "info": null,
                                  "manufacturer_id": null,
                                  "created_at": "2024-11-29 17:36:31",
                                  "updated_at": "2024-12-20 09:35:41",
                                  "condition": null
                                }
                              }
                            },
                            {
                              "id": 5,
                              "price": 738,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 738,
                                  "discountPrice": 516.6,
                                  "discount": 30
                                },
                                "bages": [
                                  {
                                    "bage": {
                                      "id": 262,
                                      "text": null,
                                      "text_color": null,
                                      "background_url": "b_262.png",
                                      "background_RGBA": null,
                                      "badge_type": 1
                                    }
                                  },
                                  {
                                    "bage": {
                                      "id": 194,
                                      "text": "Скидка –",
                                      "text_color": "#FFFFFF",
                                      "background_url": null,
                                      "background_RGBA": "#FF0000",
                                      "badge_type": 3
                                    }
                                  }
                                ],
                                "stockProduct": {
                                  "id": 87,
                                  "name": "ВАША ВЫГОДА!",
                                  "name_slug": "vasha-vygoda",
                                  "block_array": "77",
                                  "newspaper_id": 35,
                                  "type": 1,
                                  "text": null,
                                  "bottom_description": null,
                                  "background_url": null,
                                  "show_sidebar": 1,
                                  "show_count": 1,
                                  "show_search": 1,
                                  "category_main": null,
                                  "meta_tags": "{\"seo_title\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Макеевка, Донецк, Енакиево, Мариуполь.\", \"robots_index\": \"yes\", \"robots_follow\": \"yes\", \"meta_discription\": \"Акция Ваша Выгода в ТЦ \\\"Галактика\\\". Скидки до -70% бытовую технику, сантехнику, мебель, строительство и ремонт, спорттовары, автотовары, посуду, текстиль, подарки. Акция проходит в Макеевке, Донецке, Енакиево, Мариуполе в Торговых центрах \\\"Галактика\\\". \"}",
                                  "active": 1,
                                  "discount_percent": null,
                                  "date_start": "2024-11-01T14:34:00.000Z",
                                  "date_end": "2025-01-14T18:00:00.000Z",
                                  "text_end": null,
                                  "badge_id": 262,
                                  "badge_array": "194, 262",
                                  "image_url": "Ваша-Выгода-в-ТЦ-Галактика.jpg",
                                  "button": null,
                                  "text_for_modal": null,
                                  "show_day": 1,
                                  "order_sidebar": 1,
                                  "show_baner_end": 1,
                                  "comment": "<br>",
                                  "info": null,
                                  "manufacturer_id": null,
                                  "created_at": "2024-11-29 17:36:31",
                                  "updated_at": "2024-12-20 09:35:41",
                                  "condition": null
                                }
                              }
                            },
                            {
                              "id": 7,
                              "price": 738,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 738
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 1
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 0
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 1,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10125073.jpg"
                          ]
                        },
                        {
                          "id": 115749,
                          "articule": "10124761",
                          "name": "Зонт дет п/авт R45 8спиц ПВХ Горохи прозрачн/чёрн 1660845",
                          "name_slug": "Zont-det-pavt-R45-8spic-PVH-Gorohi-prozrachnchern-1660845-22250556",
                          "photo_url": "10124761.jpg",
                          "count": 1,
                          "price": 320,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 320
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 320,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 320
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 320,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 320
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 320,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 320
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 320,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 320
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 1
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 1,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124761.jpg"
                          ]
                        },
                        {
                          "id": 115770,
                          "articule": "10124742",
                          "name": "Зонт детский \"Маленькое чудо\" полуавтомат прозрачный d=90см   5541067",
                          "name_slug": "Zont-detskii-Malenkoe-chudo-poluavtomat-prozrachnyi-d90sm---5541067-22250690",
                          "photo_url": "10124742.jpg",
                          "count": 10,
                          "price": 361,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 361
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 361,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 361
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 361,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 361
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 361,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 361
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 361,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 361
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 3
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 3
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 4
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 3,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 3,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 4,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124742.jpg"
                          ]
                        },
                        {
                          "id": 115747,
                          "articule": "10124759",
                          "name": "Зонт детский, My Little Pony, 8 спиц d=87см   5403527",
                          "name_slug": "Zont-detskii-My-Little-Pony-8-spic-d87sm---5403527-22250554",
                          "photo_url": "10124759.jpg",
                          "count": 2,
                          "price": 382,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 382
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 382,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 382
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 382,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 382
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 382,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 382
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 382,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 382
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 1
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 1
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 1,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 1,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124759.jpg"
                          ]
                        },
                        {
                          "id": 115756,
                          "articule": "10124770",
                          "name": "Зонт дет. \"Играем вместе\" ОРАНЖЕВАЯ КОРОВА 45см, со свистком",
                          "name_slug": "Zont-det-Igraem-vmeste-ORANJEVAYa-KOROVA-45sm-so-svistkom-22119400",
                          "photo_url": "10124770.jpg",
                          "count": 3,
                          "price": 404,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 404
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 404,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 404
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 404,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 404
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 404,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 404
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 404,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 404
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 3
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 0
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 3,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124770.jpg"
                          ]
                        },
                        {
                          "id": 115763,
                          "articule": "10124736",
                          "name": "Зонт детский \"Единорог\", фиолетовый 3623427",
                          "name_slug": "Zont-detskii-Edinorog-fioletovyi-3623427-22250668",
                          "photo_url": "10124736.jpg",
                          "count": 1,
                          "price": 850,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 850
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 850,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 850
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 850,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 850
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 850,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 850
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 850,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 850
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 0
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 1
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 1,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124736.jpg"
                          ]
                        },
                        {
                          "id": 115769,
                          "articule": "10124741",
                          "name": "Зонт детский \"Внедорожник\" полуавтомат прозрачный d=90см   5541066",
                          "name_slug": "Zont-detskii-Vnedorojnik-poluavtomat-prozrachnyi-d90sm---5541066-22250689",
                          "photo_url": "10124741.jpg",
                          "count": 4,
                          "price": 689,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 689
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 689,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 689
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 689,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 689
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 689,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 689
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 689,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 689
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 2
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 2
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 2,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 2,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124741.jpg"
                          ]
                        },
                        {
                          "id": 115751,
                          "articule": "10124763",
                          "name": "Зонт детский \"Горошек\" МИКС  3776165",
                          "name_slug": "Zont-detskii-Goroshek-MIKS--3776165-22250558",
                          "photo_url": "10124763.jpg",
                          "count": 2,
                          "price": 586.3,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 586.3
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 586.3,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 586.3
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 586.3,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 586.3
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 586.3,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 586.3
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 586.3,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 586.3
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 2
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 2,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124763.jpg"
                          ]
                        },
                        {
                          "id": 115727,
                          "articule": "10124773",
                          "name": "Зонт детский, диаметр 46 см. в/пZY801498",
                          "name_slug": "Zont-detskii-diametr-46-sm-vpZY801498-22250409",
                          "photo_url": "10124773.jpg",
                          "count": 1,
                          "price": 432.75,
                          "discount_price": null,
                          "stock": null,
                          "unit": null,
                          "priceBadgeStock": {
                            "price": {
                              "price": 432.75
                            },
                            "bages": [],
                            "stockProduct": []
                          },
                          "tc": [
                            {
                              "id": 1,
                              "price": 432.75,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 432.75
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 4,
                              "price": 432.75,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 432.75
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 5,
                              "price": 432.75,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 432.75
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            },
                            {
                              "id": 7,
                              "price": 432.75,
                              "priceBadgeStock": {
                                "price": {
                                  "price": 432.75
                                },
                                "bages": [],
                                "stockProduct": []
                              }
                            }
                          ],
                          "status": [
                            {
                              "tc_id": 1,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": true
                            },
                            {
                              "tc_id": 4,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 5,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 7,
                              "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                              "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                              "icon": "1732027535.svg",
                              "cardActive": false,
                              "defaultTc": false
                            },
                            {
                              "tc_id": 99,
                              "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                              "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                              "icon": "1732027527.svg",
                              "cardActive": true,
                              "defaultTc": false
                            }
                          ],
                          "count_center": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 1
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0
                            }
                          ],
                          "count_center_status": [
                            {
                              "center_id": 1,
                              "name": "TЦ №1",
                              "address": "г. Макеевка, просп. 250-летия Донбасса, 74",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 1,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": true
                              }
                            },
                            {
                              "center_id": 4,
                              "name": "TЦ №4",
                              "address": "г. Донецк ЖД, ул. Соколиная, 38",
                              "count_tc": 1,
                              "status": {
                                "tc_id": 4,
                                "name_card": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">Товар есть в наличии</font>",
                                "name_cat": "<font color=\"#b92562\" size=\"2\" face=\"Glober\">В наличии</font>",
                                "icon": "1732027527.svg",
                                "cardActive": true,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 5,
                              "name": "TЦ №5",
                              "address": "г. Енакиево, пр-т Металлургов, 65А",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 5,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            },
                            {
                              "center_id": 7,
                              "name": "ТЦ №7",
                              "address": "г. Мариуполь, Запорожское шоссе, 4",
                              "count_tc": 0,
                              "status": {
                                "tc_id": 7,
                                "name_card": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Товара нет в наличии</font>",
                                "name_cat": "<font color=\"#a2a2a2\" size=\"2\" face=\"Glober\">Нет в наличии</font> ",
                                "icon": "1732027535.svg",
                                "cardActive": false,
                                "defaultTc": false
                              }
                            }
                          ],
                          "prodPhoto": [
                            "/uploads/products/main_origin/10124773.jpg"
                          ]
                        }
                      ]
                    setProducts(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getTexts = () => {
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/cart-text`)
            .then(response => {
                if (response.data?.text1) setTextAlert(response.data?.text1)
            })
            .catch(err => {
                console.error(err)
            })
    }


    const removeProductCart = (id) => {
        removeProduct(id)
        const filtered = products.filter(i => i.id !== id)
        setProducts(filtered)
    }


    return (
        <main className='page-cart'>
            {
                isLoading ?
                    <Container>
                        <div className="cart-empty">
                            <h3 className='text-left'>Корзина</h3>
                            <p>Загрузка...</p>
                        </div>
                    </Container>
                    :
                    <>{
                        products.length === 0 &&
                        <Container>
                            <div className="cart-empty">
                                <h3>Корзина пуста</h3>
                                <NavLink to="/" className="btn btn-primary">Вернуться на главную</NavLink>
                            </div>
                        </Container>
                    }

                        {
                            products.length > 0 &&
                            <Container >
                                <h3>Корзина</h3>

                                {
                                    textAlert?.text &&
                                    <AlertCart data={textAlert} />
                                }
                                <div className='overflow-hidden mb-3 lg-mb-5'>
                                    <div className="page-cart-container">
                                        <div className="cart-items">
                                            <div className="cart-items__wrap">
                                                {
                                                    isIterableArray(products) &&
                                                    products.map((product, index) => (
                                                        <CartItem
                                                            key={index}
                                                            product={product}
                                                            index={index}
                                                            isControl={true}
                                                            removeProductCart={removeProductCart}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page-cart-footer">
                                        <NavLink to="/" className='to-back'>
                                            <Button variant="link" className="open-cart" >Вернуться к покупкам</Button>
                                        </NavLink>
                                        <div className="page-cart-footer__content">

                                            <p className='total-title'>Товаров в корзине: {getTotalCount(products)}</p>
                                            {
                                                parseFloat((getTotalWithoutSale(products) * 1).toFixed(2) - (getTotalSumm(products) * 1).toFixed(2)) > 0 &&
                                                <>
                                                    <div className='page-cart-footer__content--price-row'>
                                                        <p>Цена:</p>
                                                        <p>
                                                            <span className="i-rub">{parseFloat((getTotalWithoutSale(products) * 1).toFixed(2)).toLocaleString('ru')}</span>
                                                        </p>
                                                    </div>
                                                    <div className='page-cart-footer__content--price-row'>
                                                        <p>Скидка:</p>
                                                        <p>
                                                            <span className="i-rub text-sale">{parseFloat((getTotalWithoutSale(products) * 1).toFixed(2) - (getTotalSumm(products) * 1).toFixed(2)).toLocaleString('ru')}</span>
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                            <div className='page-cart-footer__content_btn'>
                                                <p className='text-center'>
                                                    {
                                                        parseFloat((getTotalWithoutSale(products) * 1).toFixed(2) - (getTotalSumm(products) * 1).toFixed(2)) > 0 ? 'Итого: ' : 'Цена: '
                                                    }
                                                    <span className="i-rub">{parseFloat((getTotalSumm(products) * 1).toFixed(2)).toLocaleString('ru')}</span>
                                                </p>
                                                <NavLink to="/checkout" className="btn btn-primary">Оформить заказ</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <SliderFetchWrapper
                                    urlFetch={`${process.env.REACT_APP_API_BASE}/pages/cart-slider`}
                                />

                            </Container>
                        }
                    </>
            }
        </main>
    )
}

export default PageCart