
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import { isIterableArray } from '../block-slider/utils';
import RenderBlocks from './blocks/RenderBlocks';
import SideMenu, { SideMenuLinkWrapper } from './SideMenu';
import "./builderPage.scss"
import SkeletonMenu from './SkeletonMenu';


export const setMetaTags = (metaData) => {
    let metaTitle, metaDescription, robotsIndex, robotsFollow
    if (metaData && metaData.seo_title) {
        metaTitle = metaData.seo_title
    }
    if (metaData && metaData.meta_discription) {
        metaDescription = metaData.meta_discription
    }
    if (metaData && metaData.robots) {
        robotsIndex = metaData.robots
    }
    if (metaData && metaData.follow) {
        robotsFollow = metaData.follow
    }

    if (metaTitle) document.title = metaTitle;
    else document.title = 'Галактика';
    if (metaDescription) {
        if (!document.querySelector('meta[name="description"]')) {
            let metaD = document.createElement('meta')
            metaD.name = 'description'
            metaD.content = metaDescription
            document.querySelector('head').appendChild(metaD)
        } else {
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
        }
    }
    document.querySelectorAll('meta[name="robots"]').forEach((el) => {
        document.querySelector('head').removeChild(el)
    })
    if (robotsIndex || robotsFollow) {
        let metaD = document.createElement('meta')
        metaD.name = 'robots'
        let robotsContent = ''
        if (robotsIndex === 'yes') {
            robotsContent += 'index'
            if (robotsFollow === 'yes') {
                robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
                robotsContent += ', nofollow'
            }
        } else if (robotsIndex === 'no') {
            robotsContent += 'noindex'
            if (robotsFollow === 'yes') {
                robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
                robotsContent += ', nofollow'
            }
        } else {
            if (robotsFollow === 'yes') {
                robotsContent += 'follow'
            }
            if (robotsFollow === 'no') {
                robotsContent += 'nofollow'
            }
        }
        metaD.content = robotsContent
        document.querySelector('head').appendChild(metaD)
    }
}

const BuilderPage = () => {

    const { pageSlug } = useParams()

    const [data, setData] = useState(null);
    const [menuData, setMenuData] = useState(null);
    const [isMenu, setIsMenu] = useState(false);

    const [crumbs, setCrumbs] = useState([]);

    useEffect(() => {
        fetchData()
    }, [pageSlug]);

    useEffect(() => {
        if (data) {
            data?.slug === 'info' ?
                setCrumbs([
                    {
                        name: data?.name,
                        pageSlug: false
                    }
                ])
                :
                setCrumbs([
                    {
                        name: 'Информация',
                        pageSlug: 'info'
                    },
                    {
                        name: data?.name,
                        pageSlug: false
                    },
                ])
        }
        else {
            setCrumbs([])
        }
    }, [data]);

    // const createCrumbs = (items) => {
    //     const res = []
    //     if (isIterableArray(items)) {
    //         items.forEach(element => {
    //             if (element.open) {
    //                 res.push({
    //                     id: element.id,
    //                     name: element.name,
    //                     link: element.link,
    //                     pageSlug: element.pageSlug,

    //                 })
    //                 if (isIterableArray(element.subItems)) {
    //                     const ch = createCrumbs(element.subItems)
    //                     if (isIterableArray(ch)) {
    //                         ch.forEach(ch_el => {
    //                             res.push(ch_el)
    //                         });
    //                     }
    //                 }
    //             }
    //         });
    //     }
    //     // return [res[res.length - 1]]
    //     return [
    //         {
    //             name: 'Info',
    //             pageSlug: '/info'
    //         },
    //         {
    //             name: data?.name,
    //             pageSlug: false
    //         },

    //     ]
    // }

    const fetchData = () => {

        setData(null)
        // setMenuData(null)

        axios.get(`${process.env.REACT_APP_API_BASE}/pages/page/${pageSlug || 'info'}`)
            .then((response) => {
                console.log('page data response', response)

                setData(response.data)
                setMenuData(response.data.sidebar)
                setIsMenu(!!response.data.sidebar)

                if (response.data.meta_tags) setMetaTags(response.data.meta_tags)

                // if (isIterableArray(response?.data?.sidebar?.items)) {
                //     const crumbs = createCrumbs(response.data.sidebar.items)
                //     setCrumbs(crumbs)
                // }
                // else {
                //     setCrumbs([])
                // }
            })
    }


    return (
        <div className='buildedPage'>
            <Breadcrumb>
                <Breadcrumb.Item href={process.env.REACT_APP_CLIENT}><i className="i-home"></i></Breadcrumb.Item>
                {
                    isIterableArray(crumbs) &&
                    crumbs.map((item, key) => (
                        <li className={'breadcrumb-item'} key={key}>
                            <SideMenuLinkWrapper link={item.link} pageSlug={item.pageSlug}>
                                <span dangerouslySetInnerHTML={{ __html: item.name }} />
                            </SideMenuLinkWrapper>
                        </li>
                    ))
                }
            </Breadcrumb>
            <div className='buildedPage-container'>
                {
                    menuData ?
                        <SideMenu menuData={menuData} />
                        :
                        isMenu ?
                            <SkeletonMenu />
                            :
                            ''
                }

                <div className='buildedPage-content'>
                    {
                        data?.name &&
                        <h1>{data.name}</h1>
                    }
                    {
                        isIterableArray(data?.content) &&
                        <RenderBlocks data={data.content} />
                    }
                </div>
            </div>
        </div>
    )
}

export default BuilderPage
