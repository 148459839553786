//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Container } from 'react-bootstrap';
import axios from 'axios';

//My Components
import ReadMore from '../main/ReadMore';
// import SliderProductsOnlyG from '../main/SliderProductsOnlyG';
// import SliderProductsNew from '../main/SliderProductsNew';
// import DropdownProductsNew from '../main/DropdownProductsNew'
import SliderProductsLastView from '../main/SliderProductLastView';
import Slider from './Slider'

import '../../scss/components/pages/categories/catChild.scss';
import { isIterableArray, isNotNullText } from '../block-slider/utils';

import SliderFetchWrapper from '../block-slider/SliderFetchWrapper';

class PageCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banners: [],
            current: [],
            categories: [],
            products: [],
            crumbs: [],
            // slidersData: []
        }
    }
    componentDidUpdate(prevProps, prevState) {
        document.querySelector('#menu-underlay.open') && document.querySelector('#menu-underlay.open').click()
        //console.log(prevProps.match.params.slug, this.props.match.params.slug);
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.fetchData()
        }
    }
    fetchData() {
        if (typeof this._source != typeof undefined) {
            this._source.cancel("Operation canceled due to new request.");
        }
        this._source = axios.CancelToken.source();
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-page/${this.props.match.params.slug}/1`, { cancelToken: this._source.token })
            .then(response => {
                // console.log(response.data.data[2])
                // if(response.data.data[2].redirect !== null) {
                //     // window.location.reload()
                //     if(response.data.data[2].redirect.redirect_type === 'prod') {
                //         window.location.replace(`/product/${response.data.data[2].redirect.redirect_to}`)
                //     }
                //     if(response.data.data[2].redirect.redirect_type === 'cat') {
                //         // console.log(response.data.data[0])
                //         if(response.data.data[2].redirect.type_cat === 'no_have_cat') {
                //             window.location.replace(`/catalog/${response.data.data[2].redirect.redirect_to}`)
                //         } else {
                //             window.location.replace(`/category/${response.data.data[2].redirect.redirect_to}`)
                //         }
                //     }
                // }
                this.setState({
                    banners: response.data.data[3],
                    current: response.data.data[2],
                    categories: response.data.data[1],
                    products: response.data.data[0],
                    crumbs: response.data.data[4].reverse()
                })
                // console.log(this.state.categories)
                let metaTitle, metaDescription, robotsIndex, robotsFollow
                if (response.data.data[2].meta_tags && response.data.data[2].meta_tags.seo_title) {
                    metaTitle = response.data.data[2].meta_tags.seo_title
                }
                if (response.data.data[2].meta_tags && response.data.data[2].meta_tags.meta_discription) {
                    metaDescription = response.data.data[2].meta_tags.meta_discription
                }
                if (response.data.data[2].meta_tags && response.data.data[2].meta_tags.robots_index) {
                    robotsIndex = response.data.data[2].meta_tags.robots_index
                }
                if (response.data.data[2].meta_tags && response.data.data[2].meta_tags.robots_follow) {
                    robotsFollow = response.data.data[2].meta_tags.robots_follow
                }

                if (metaTitle) document.title = metaTitle;
                else document.title = this.state.current.name + ' | Галактика';
                if (metaDescription) {
                    if (!document.querySelector('meta[name="description"]')) {
                        let metaD = document.createElement('meta')
                        metaD.name = 'description'
                        metaD.content = metaDescription
                        document.querySelector('head').appendChild(metaD)
                    } else {
                        document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
                    }
                }
                document.querySelectorAll('meta[name="robots"]').forEach((el) => {
                    document.querySelector('head').removeChild(el)
                })
                if (robotsIndex || robotsFollow) {
                    let metaD = document.createElement('meta')
                    metaD.name = 'robots'
                    let robotsContent = ''
                    if (robotsIndex === 'yes') {
                        robotsContent += 'index'
                        if (robotsFollow === 'yes') {
                            robotsContent += ', follow'
                        }
                        if (robotsFollow === 'no') {
                            robotsContent += ', nofollow'
                        }
                    } else if (robotsIndex === 'no') {
                        robotsContent += 'noindex'
                        if (robotsFollow === 'yes') {
                            robotsContent += ', follow'
                        }
                        if (robotsFollow === 'no') {
                            robotsContent += ', nofollow'
                        }
                    } else {
                        if (robotsFollow === 'yes') {
                            robotsContent += 'follow'
                        }
                        if (robotsFollow === 'no') {
                            robotsContent += 'nofollow'
                        }
                    }
                    metaD.content = robotsContent
                    document.querySelector('head').appendChild(metaD)
                }
            })
            .catch(error => {
                console.error(error)
                window.location.href = '/404'
            })

    }
    // fetchSlider() {

    //     console.log('ererer')

    //     axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-slider/${this.props.match.params.slug}`, { cancelToken: this._source.token })
    //         .then(response => {

    //             console.log('23432')
    //             if (isIterableArray(response?.data?.data)) {
    //                 this.setState({
    //                     slidersData: response.data.data,
    //                 })
    //             }
    //         })
    //         .catch(error => {
    //             console.error(error)
    //         })
    // }
    componentDidMount() {
        this.fetchData();
        // this.fetchSlider();
    }
    render() {
        return (
            <>
                <main className='page-category'>
                    <Container>
                        <Breadcrumb>
                            <li className={'breadcrumb-item'}>
                                <Link to={`/`}>
                                    <i className="i-home"></i>
                                </Link>
                            </li>
                            {this.state.crumbs.length > 0 &&
                                this.state.crumbs.map((c, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {c.redirect === null &&
                                                <li className={'breadcrumb-item'} key={i}>
                                                    {this.state.crumbs.length - 1 === i &&
                                                        <span>
                                                            {c.name}
                                                        </span>
                                                    }
                                                    {this.state.crumbs.length - 1 !== i &&
                                                        <Link to={`/category/${c.name_slug}`}>
                                                            {c.name}
                                                        </Link>
                                                    }
                                                </li>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Breadcrumb>
                        <h1>{this.state.current.name}</h1>
                        {
                            isNotNullText(this.state.current.short_description) &&
                            <ReadMore
                                initialHeight={50}
                                readMore={props => (
                                    <div className='readmore__button' onClick={props.onClick}>
                                        {props.open ? '' : 'Читать дальше'}
                                    </div>
                                )}
                            >
                                <div className="readmore__caption" dangerouslySetInnerHTML={{ __html: this.state.current.short_description }}>
                                </div>
                            </ReadMore>
                        }
                        {this.state.banners.length > 0 &&
                            <Slider items={this.state.banners} />
                        }
                        <div className="categories">

                            {this.state.categories.length > 0 && this.state.categories.map((category, index) => {

                                return (
                                    <React.Fragment key={index}>
                                        {category.categoryData.redirect === null &&
                                            <div className="categories__item" key={index}>
                                                <div className="categories__item-wrap">
                                                    <Link className="category-link" to={`/${category.categoryData.type === 'have_cat' ? 'category' : 'catalog'}/${category.categoryData.name_slug}`}>
                                                        <div className="category-image">
                                                            <img
                                                                id={`cat-img-${category.categoryData.id}`}
                                                                src={
                                                                    category.categoryData.image_url ?
                                                                        `${process.env.REACT_APP_BACKEND}/uploads/categories_image/${category.categoryData.image_url}`
                                                                        :
                                                                        `https://via.placeholder.com/150x150/7C46AD/FFFFFF?text=GALAKTIKA`
                                                                }
                                                                alt={category.categoryData.name}
                                                            />
                                                        </div>
                                                        <p className="category-name">{category.categoryData.name}</p>
                                                    </Link>
                                                    <div className="category-child">
                                                        <div className="category-child__wrap"
                                                            onMouseLeave={() => {
                                                                document
                                                                    .getElementById(`cat-img-${category.categoryData.id}`)
                                                                    .src = `${process.env.REACT_APP_BACKEND}/uploads/categories_image/${category.categoryData.image_url}`
                                                            }}>
                                                            {category.subCategories.length > 0 && category.subCategories.map((subcat, index) => {
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        {subcat.redirect === null &&
                                                                            <Link key={index}
                                                                                className="category-child__item"
                                                                                to={`/${subcat.type === 'have_cat' ? 'category' : 'catalog'}/${subcat.name_slug}`}
                                                                                onMouseEnter={() => {
                                                                                    document
                                                                                        .getElementById(`cat-img-${category.categoryData.id}`)
                                                                                        .src = `${process.env.REACT_APP_BACKEND}/uploads/categories_image/${subcat.image_url}`
                                                                                }}>
                                                                                {subcat.name}
                                                                            </Link>
                                                                        }
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                )

                            })}

                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {this.state.products.length > 0 &&
                                    this.state.products.map((block, i) => {
                                        return (
                                            <SliderProductsLastView items={block} key={i} />
                                        )
                                    })}
                            </div>


                        </div>

                        <SliderFetchWrapper
                            urlFetch={`${process.env.REACT_APP_API_BASE}/pages/category-slider/${this.props.match.params.slug}`}
                        />

                        {this.state.current.bottom_description &&
                            <div className="" dangerouslySetInnerHTML={{ __html: this.state.current.bottom_description }}>
                            </div>
                        }
                    </Container>
                </main>
            </>
        );
    }
}

export default PageCategory;
