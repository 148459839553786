import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import WishList from "../main/ModalWishlist";
import Badges from "../main/Badges";
import { Button } from 'react-bootstrap';
import CartCtx from '../CartCtx'
import { storageProductOffsetTop, storageProductsChars } from './utils';

import axios from 'axios'
import { isIterableArray } from '../block-slider/utils';
import Slider from 'react-slick';
import CatalogColorChar from './character/catalog/CatalogColorChar';
import CatalogChartListMobile from './character/catalog/CatalogChartListMobile';
import CatalogChartListDesktop from './character/catalog/CatalogChartListDesktop';

import logo from '../../img/logo-grey.svg'

// const storageProductsChars = 'galaxy-products-chars'
// const storageProductOffsetTop = 'galaxy-products-offset-top'



// получить все выбрананные фильтры из массива характеристик
export const getChars = (arr) => {

    let char_res = []

    if (isIterableArray(arr)) {
        arr.forEach(element => {
            isIterableArray(element.value_arr) &&
                element.value_arr.forEach(char_el => {
                    if (char_el.selected) {
                        let formatChar = {
                            filter_id: element.filter_id,
                            filter_option_id: char_el.filter_option_id,
                            title: char_el.title
                        }
                        char_res.push(formatChar)
                    }
                })
        });
    }
    else if (isIterableArray(arr?.value_arr)) {
        arr.value_arr.forEach(char_el => {
            if (char_el.selected) {
                let formatChar = {
                    filter_id: arr.filter_id,
                    filter_option_id: char_el.filter_option_id,
                    title: char_el.title
                }
                char_res.push(formatChar)
            }
        })
    }

    return char_res
}

export const saveToStorage = (newDataChars, prev_product_id, new_product_id, name_slug, category_slug) => {

    const storageCharsData = localStorage.getItem(storageProductsChars) ? JSON.parse(localStorage.getItem(storageProductsChars)) : []

    const isExist = storageCharsData.findIndex(item => item.product_id == prev_product_id)

    if (isExist >= 0) {
        storageCharsData[isExist] = {
            product_id: new_product_id,
            slug: category_slug,
            chars: newDataChars || [],
            product_slug: name_slug,
        }
        localStorage.setItem(storageProductsChars, JSON.stringify(storageCharsData))
    }
    else {
        if (isIterableArray(newDataChars)) {
            storageCharsData.push({
                product_id: new_product_id,
                slug: category_slug,
                chars: newDataChars || [],
                product_slug: name_slug,
            })
            localStorage.setItem(storageProductsChars, JSON.stringify(storageCharsData))
        }
    }
}

export const getCountWord = (val) => {
    // вариант - 1 21 31 41 
    // варианта - 2 3 4  22 23 24 32 33 34 
    // вариантов - 5 6 7 8 9 10 11 12 13 14 15 17 18 19 20 25 
    const count = Number(val)

    if (
        count === 1 ||
        count === 21 ||
        count === 31 ||
        count === 41 ||
        count === 51 ||
        count === 61
    ) return "вариант";
    if (count > 1 && count < 5) return "варианта";
    if (count >= 5 && count < 21) return "вариантов";
    if (count > 21 && count < 25) return "варианта";
    if (count >= 25 && count < 31) return "вариантов";
    if (count > 31 && count < 35) return "варианта";
    if (count >= 35 && count < 41) return "вариантов";
    if (count > 41 && count < 45) return "варианта";
    if (count >= 45 && count < 51) return "вариантов";
    if (count > 51 && count < 55) return "варианта";
    if (count >= 55 && count < 61) return "вариантов";

    if (count > 61) return "вариант";

    return ''
}


const ProductCard = ({ product, context, isProductsHasVariable, openInfoModal }) => {

    const { slug } = useParams()

    const { selectedCenter } = context

    const cardRef = useRef(null)
    const prodSliderRef = useRef(null)

    const isVariableWidthSliderImage = false

    const [isLoadingCard, setIsLoadingCard] = useState(false);
    const [productState, setProductState] = useState(product);
    const [productsPrice, setProductPrice] = useState({
        price: product.price,
        priceBadgeStock: product.priceBadgeStock
    }); // цена у выбранного тц

    const [productStatus, setProductStatus] = useState(null);

    const [selectedChar, setSelectedChar] = useState(defaultSelectedChar);

    const [isOpenMobileChar, setIsOpenMobileChar] = useState(false);

    const { photo_url, name, sale, stockStatus, count } = productState

    const defaultSelectedChar = {
        product_id: productState.id,
        product_slug: productState.name_slug,
        slug: productState.category_id,
        chars: []
    }

    useEffect(() => {
        setProductState(product)
    }, [product]);
    const handleFetchNewChar = (filterSelectedLast, charsData) => {
        setIsLoadingCard(true)
        setProductState(prev => {
            return {
                ...prev, prodPhoto: []
            }
        })

        const selectedCenterId = context.selectedCenter ? context.selectedCenter.id : false

        let postData = {
            filterSelectedLast: filterSelectedLast,
            filtersSelected: charsData?.chars || [],
            product_id: productState.id,
            tc_id: selectedCenterId
        };
        // console.log('product filter postData', postData, selectedChar)
        axios.post(`${process.env.REACT_APP_API_BASE}/pages/product-filter`, postData)
            .then((response) => {
                console.log(response.status, response)

                if (response.status === 200) {
                    if (response.data.data) {


                        const selectedDefaultChar = getChars(response.data.data.char)
                        const selectedDefaultColor = getChars(response.data.data.color)

                        const newChars = [...selectedDefaultChar, ...selectedDefaultColor]

                        saveToStorage(newChars, productState.id, response.data.data.id, response.data.data.name_slug, response.data.data.category_id)

                        setSelectedChar({
                            product_slug: response.data.data.name_slug,
                            slug: response.data.data.category_id,
                            product_id: response.data.data.id,
                            chars: newChars
                        })
                        setProductState(prev => {
                            return {
                                ...prev, ...response.data.data
                            }
                        })
                    }
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoadingCard(false)
            })
        // setProductState(prev => ({ ...prev, ...{ updated: 1 } }))
    }


    const handleSelectChar = new_v => {
        handleFetchNewChar(new_v, selectedChar)
        if (isOpenMobileChar) setIsOpenMobileChar(false)
    }



    const handleLinkToProduct = () => {
        const offsetTop = cardRef.current?.offsetTop
        if (offsetTop) sessionStorage.setItem(storageProductOffsetTop, offsetTop)

    }


    const getCountedSelected = (chars) => {
        if (isIterableArray(chars)) {
            const res = chars.reduce((acc, item) => {
                if (isIterableArray(item.value_arr)) {
                    const indexSelected = item.value_arr.findIndex(ch_i => ch_i.selected === true)
                    if (indexSelected >= 0) acc++
                }
                return acc
            }, 0)
            if (res > 0) return <span className='selected-text'>(Выбрано {res})</span>
            return ''
        }
        return ''
    }


    const sliderSettings = {
        infinite: true,
        dots: true,
        arrows: true,
        autoplay: false,
        draggable: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0px',
        variableWidth: isVariableWidthSliderImage,
        // initialSlide: isLoadingCard ? 1 : 2 ,
    };


    const videoObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                // элемент появляется на экране

                const video = cardRef.current.querySelector('.video-index-0')

                if (video) {
                    prodSliderRef.current.slickGoTo(0, false)
                    video.play()
                }
            } else {
                // элемент не видим на экране

                const video = cardRef.current.querySelector('.video-index-0')
                if (video) {
                    video.currentTime = 0
                    video.pause()
                }
            }
        });
    });

    useEffect(() => {
        if (cardRef.current) {
            videoObserver.observe(cardRef.current);
        }
        return () => {
            if (cardRef.current) videoObserver.unobserve(cardRef.current)
        }
    }, [cardRef.current]);

    useEffect(() => {
        if (prodSliderRef.current && cardRef.current) {
            const video = cardRef.current.querySelector('video')
            if (video) {
                video.addEventListener('ended', () => {
                    prodSliderRef.current.slickNext()
                })
            }
        }
        // return () => {
        //     cleanup
        // };
    }, [prodSliderRef.current]);


    // если выбран тц - меняем цену 
    useEffect(() => {
        if (selectedCenter?.id) {
            if (isIterableArray(productState.tc)) {

                const selectedPrice = productState.tc.find(item => item.id == selectedCenter.id)

                const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.tc_id == 99) : false
                // const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.tc_id == selectedCenter.id) : false
                if (selectedStatus?.tc_id) setProductStatus(selectedStatus)
                else setProductStatus(null)

                setProductPrice({
                    price: selectedPrice.price,
                    priceBadgeStock: selectedPrice.priceBadgeStock
                })
            }
        }
        else if (productState) {
            setProductPrice({
                price: productState.price,
                priceBadgeStock: productState.priceBadgeStock
            })
            const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.tc_id === 99) : false
            // const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.defaultTc === true) : false
            if (selectedStatus?.tc_id) setProductStatus(selectedStatus)
            else setProductStatus(null)
        }
    }, [selectedCenter?.id, productState]);


    return (
        <React.Fragment>
            {
                // productState.redirect === null &&
                <div ref={cardRef} className={`catalog-list__item  ${isProductsHasVariable ? 'with-variants' : ''}`} >
                    <div className="products__wrap d-flex flex-column justify-content-between">

                        <div className={`d-lg-none mobile-char ${isOpenMobileChar ? 'open' : ''}`}>

                            <div className='mobile-char-wrap' onClick={(e) => {
                                e.preventDefault()
                                setIsOpenMobileChar(false)
                            }}>
                            </div>
                            <div className='mobile-char-container'>
                                <div className='mobile-char-btn-close' onClick={(e) => {
                                    e.preventDefault()
                                    setIsOpenMobileChar(false)
                                }} >
                                    ×
                                </div>
                                <CatalogChartListMobile
                                    handleSelectChar={handleSelectChar}
                                    character={productState.char}
                                    isLoadingCard={isLoadingCard}
                                />
                                {
                                    isIterableArray(productState.character) &&
                                    <div className='character-text'>
                                        <p>Характеристики:</p>
                                        {productState.character.map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    {item.nameChar}: {item.valueChar}; <br />
                                                </span>
                                            )
                                        })}
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="btn-toolbar">
                            <WishList productId={productState.id} productCount={productState.count} />
                        </div>
                        {/* ${productState.color ? '' : 'with-variants'} */}
                        {/* ${isProductsHasVariable ? '' : 'with-variants'} */}
                        <div className={`products-image `}>
                            {
                                !isLoadingCard ?
                                    (
                                        isIterableArray(productState.prodPhoto) ?
                                            <Slider {...sliderSettings} ref={prodSliderRef}>
                                                {
                                                    productState.prodPhoto.map((image_path, key) => (
                                                        <Link
                                                            key={key}
                                                            onClick={handleLinkToProduct}
                                                            className="products-image__link"
                                                            to={`/product/${productState.name_slug}`}
                                                        >
                                                            {
                                                                image_path.includes('.mp4') ?
                                                                    <video className={`video-index-${key}`} playsInline src={process.env.REACT_APP_BACKEND + image_path} controls={false} muted loop={productState.prodPhoto.length < 2} />
                                                                    :
                                                                    <img src={process.env.REACT_APP_BACKEND + image_path}
                                                                        alt={("Изображение товара " + productState.name)} />
                                                            }
                                                        </Link>
                                                    ))
                                                }
                                            </Slider>
                                            :
                                            <Link
                                                onClick={handleLinkToProduct}
                                                to={`/product/${productState.name_slug}`}
                                                className="products-image__link"
                                            >
                                                {
                                                    photo_url ?
                                                        (photo_url.includes('.mp4') ?
                                                            <video src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + photo_url} autoPlay loop controls={false} muted playsInline />
                                                            :
                                                            <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + photo_url}
                                                                alt={("Изображение товара " + productState.name)} />
                                                        )
                                                        :
                                                        ''//esli net kartinki
                                                }
                                            </Link>
                                    )
                                    :
                                    <div className='logo-loader'>
                                        <img src={logo} alt="" />
                                    </div>
                            }

                            {
                                productState.showCountVar &&
                                <div className='products-variants-count'>
                                    {productState.showCountVar} {getCountWord(productState.showCountVar)}
                                </div>

                            }
                        </div>

                        <CatalogColorChar
                            character={productState.color}
                            handleSelectChar={handleSelectChar}
                            isLoadingCard={isLoadingCard}
                            isProductsHasVariable={isProductsHasVariable}
                        />


                        <div className='d-flex flex-column'
                            style={{
                                zIndex: 2,
                                transition: 'opacity .4s ease',
                                opacity: isLoadingCard ? 0.5 : 1,
                                pointerEvents: isLoadingCard ? 'none' : 'auto'
                            }}
                        >
                            <Link
                                onClick={handleLinkToProduct}
                                // to={`/product/${productState.name_slug}`}
                                to={`/product/${productState.name_slug}`}
                                className="products-title"
                                title={name}
                            >
                                {name}
                            </Link>

                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <div className={("products-price " + sale + " " + stockStatus)}>
                                        <div className="producst-price__wrap">
                                            <div className="products-price__old">
                                                {productsPrice.priceBadgeStock.price.discountPrice &&
                                                    <span
                                                        className="price i-rub d-inline-block">{productsPrice.priceBadgeStock.price.price.toLocaleString('ru')}</span>
                                                }
                                            </div>
                                            <div
                                                className="products-price__main d-flex align-items-end"
                                            // style={{ flexWrap: 'wrap' }} если включить перенос то может поплыть верстка 
                                            >
                                                {productsPrice.priceBadgeStock.price.discountPrice &&
                                                    <span className="price i-rub" style={{ color: '#d81137' }}>
                                                        {productsPrice.priceBadgeStock.price.discountPrice.toLocaleString('ru')}
                                                    </span>
                                                }
                                                {!productsPrice.priceBadgeStock.price.discountPrice &&
                                                    <span className="price i-rub">
                                                        {productsPrice.price.toLocaleString('ru')}
                                                    </span>
                                                }
                                                {
                                                    productState?.unit &&
                                                    <div className='price-unit' style={{ fontSize: 14 }}>
                                                        <span>
                                                            /
                                                        </span>
                                                        <span>
                                                            {productState.unit}
                                                        </span>
                                                    </div>
                                                }
                                            </div>


                                        </div>
                                        <div className="products-cart-sm">
                                            {count > 0 && process.env.REACT_APP_TEST === 1 &&
                                                <>
                                                    {!context.isInCart(productState.id) &&
                                                        <CartCtx.Consumer>
                                                            {({ cart, addProduct }) => (
                                                                <Button variant="simple" className="add-to-cart"
                                                                    onClick={() => addProduct(productState.id)}><i className="i-cart"></i>
                                                                </Button>
                                                            )}
                                                        </CartCtx.Consumer>
                                                    }
                                                    {context.isInCart(productState.id) &&
                                                        <CartCtx.Consumer>
                                                            {({ cart, addProduct }) => (
                                                                <Button variant="simple" className="add-to-cart added" disabled>
                                                                    <i className="i-cart" /></Button>
                                                            )}
                                                        </CartCtx.Consumer>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {
                                        productStatus ?
                                            <div className='products-stock d-flex align-items-center  mt-2' style={{ gap: 4 }}>
                                                {
                                                    productStatus.icon ?
                                                        <img style={{ width: 12 }} src={`${process.env.REACT_APP_BACKEND}/site_image/status_icons/${productStatus.icon}`} alt='' />
                                                        :
                                                        ''
                                                }
                                                <div dangerouslySetInnerHTML={{ __html: productStatus.name_cat }} />

                                            </div>
                                            :
                                            <div className="products-stock">
                                                {productState.count > 0 && <p className="stock-in">В наличии</p>}
                                                {productState.count < 1 && <p className="stock-out">Нет в наличии</p>}
                                            </div>
                                    }
                                </div>

                                {/* mobilra */}
                                <div className='d-lg-none'>
                                    {count > 0 && process.env.REACT_APP_TEST === '1' &&
                                        <>
                                            {!context.isInCart(productState.id) &&
                                                <CartCtx.Consumer>
                                                    {({ cart, addProduct }) => (
                                                        <div className="cart-btn">
                                                            {
                                                                isLoadingCard ?
                                                                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                                                                    </div>
                                                                    :
                                                                    <button className="btn btn-light btn-to-card" onClick={() => addProduct(productState.id)}>
                                                                        <i className="i-cart"></i>
                                                                    </button>

                                                            }
                                                        </div>
                                                    )}
                                                </CartCtx.Consumer>
                                            }
                                            {context.isInCart(productState.id) &&
                                                <CartCtx.Consumer>
                                                    {({ cart, addProduct }) => (
                                                        <div className="cart-btn">
                                                            {

                                                                isLoadingCard ?
                                                                    <div class="spinner-border spinner-border-sm text-secondary" role="status">
                                                                    </div>
                                                                    :
                                                                    <button className="btn btn-light btn-in-card" onClick={() => addProduct(productState.id)}>
                                                                        ✔
                                                                        <i className="i-cart"></i>
                                                                    </button>

                                                            }
                                                        </div>
                                                    )}
                                                </CartCtx.Consumer>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {
                                (isIterableArray(productState.char) || isIterableArray(productState.character)) &&
                                <div
                                    className='mobile-char-btn-open d-lg-none'
                                    onClick={() => { setIsOpenMobileChar(true) }}
                                >
                                    Характеристики {getCountedSelected(productState.char)}
                                    <i className="i-arrow next" style={{ display: 'inline-block' }}></i>
                                </div>
                            }

                            {productState.gift && (
                                <Link to={`/product/${productState.gift.name_slug}`} className="products-gift contents"
                                    style={{
                                        color: '#000',
                                        textDecoration: 'none',
                                        background: 'rgba(255,255,255,.5)',
                                        backdropFilter: 'blur(20px)'
                                    }}
                                >
                                    <span className="f7 fw500">Подарок</span>
                                    <img src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + productState.gift.photo_url} alt="" className="img" />
                                </Link>
                            )}
                        </div>

                        {
                            productsPrice.priceBadgeStock.bages.length > 0 &&
                            <Badges items={productsPrice.priceBadgeStock} />
                        }
                        <div className="products__footer">
                            {count > 0 && process.env.REACT_APP_TEST === '1' &&
                                <>
                                    {!context.isInCart(productState.id) &&
                                        <CartCtx.Consumer>
                                            {({ cart, addProduct }) => (
                                                <div className="cart-btn">
                                                    {
                                                        isLoadingCard ?
                                                            <Button className="btn btn-primary" style={{ pointerEvents: 'none' }}>
                                                                Загрузка ...
                                                            </Button>
                                                            :
                                                            <Button className="btn btn-primary" onClick={() => addProduct(productState.id)}>В
                                                                корзину</Button>

                                                    }
                                                </div>
                                            )}
                                        </CartCtx.Consumer>
                                    }
                                    {context.isInCart(productState.id) &&
                                        <CartCtx.Consumer>
                                            {({ cart, addProduct }) => (
                                                <div className="cart-btn">
                                                    {

                                                        isLoadingCard ?
                                                            <Button className="btn btn-primary added" style={{ pointerEvents: 'none' }}>
                                                                Загрузка ...
                                                            </Button>
                                                            :
                                                            <Button className="btn btn-primary added" onClick={() => addProduct(productState.id)}>Уже
                                                                в корзине</Button>

                                                    }
                                                </div>
                                            )}
                                        </CartCtx.Consumer>
                                    }
                                </>
                            }

                            {/*<div className="short-bages">
                                            <ul className="short-bages__wrap">
                                                <li className="bage">
                                                    <img src="img/temp/products/b01.png" alt="" />
                                                </li>
                                            </ul>
                                        </div>*/}

                            {
                                // count > 0 &&
                                <div className="short-desc">
                                    <div dangerouslySetInnerHTML={{ __html: productState.short_discription !== 'null' && productState.short_discription }} />
                                    <CatalogChartListDesktop
                                        handleSelectChar={handleSelectChar}
                                        character={productState.char}
                                        isLoadingCard={isLoadingCard}
                                        productId={productState.id}
                                    />
                                    <p>
                                        {
                                            isIterableArray(productState.character) &&
                                            productState.character.map((item, index) => {
                                                return (
                                                    <span key={index}>
                                                        {item.nameChar}: {item.valueChar}; <br />
                                                    </span>
                                                )
                                            })}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div >
            }
        </React.Fragment >
    )
}

export default ProductCard