//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import queryString from 'query-string'
import { Breadcrumb, Container, Button, Modal } from 'react-bootstrap';
// import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
//import StarRatings from 'react-star-ratings';

import CartCtx from '../CartCtx'

//My Components
import ReadMore from '../main/ReadMore';
import ImageSlider from '../categories/Slider';
// import WishList from "../main/ModalWishlist";

import '../../scss/components/pages/categories/catChild.scss';
import '../../scss/components/pages/categories/filter.scss';
import axios from "axios";
import { isIterableArray } from '../block-slider/utils';
// import ProductCard from './ProductCard';
// import PageCategoryFilters from './filters/PageCategoryFilters';
import { storageFilters, storageProductOffsetTop, storageProductsChars } from './utils';
// import Skeleton from './Skeleton'
import Catalog from './pageCategoryChild/Catalog';
import SliderFetchWrapper from '../block-slider/SliderFetchWrapper';


class PageCategoryChild extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: true,
      filterToggleOn: true,
      banners: [],
      current: {},
      products: [],
      filters: [],
      manufacturers: [],
      // products: def,
      // filters: def[1],
      // manufacturers: def[2],

      pagination: {},
      page: this.props.match.params.page || 1,
      selectedRanges: [],
      selectedFilters: [],
      selectedManufacturers: [],
      selectedColors: [],
      max_price: 0,
      order_by: 1,
      price_from: null,
      price_to: null,
      colors: [],
      crumbs: [],
      trueRobots: null,
      isLoading: false,
      // slidersData: [],
      isProductsHasVariable: true,
      // filtersStyle: {}
    };
    // this.handleScroll = this.handleScroll.bind(this);
    this.setSelectedRanges = this.setSelectedRanges.bind(this);
    this.rangeCheck = this.rangeCheck.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.filterCheck = this.filterCheck.bind(this)
    this.brandCheck = this.brandCheck.bind(this)
    this.colorCheck = this.colorCheck.bind(this)
    this.changePage = this.changePage.bind(this)
    this.filterProducts = this.filterProducts.bind(this)
    this.resetFilters = this.resetFilters.bind(this)
    this.removeFilter = this.removeFilter.bind(this)
    this.changePageAndHandle = this.changePageAndHandle.bind(this)
    this.addSomeUselessTags = this.addSomeUselessTags.bind(this)
    this.linkiHuinki = this.linkiHuinki.bind(this)
    if (window.location.pathname.substr(-2, 2) === '/1') {
      window.history.pushState(null, null, window.location.pathname.slice(0, -2))
    }
    let lastPart = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    if (lastPart !== this.props.match.params.slug && !lastPart.match(/^[0-9]+$/)) {
      window.location.replace('/404.html')
    }
    if (false) {
      window.location.replace('/404.html')
    }
  }


  rangeCheck() {

    let selected = []

    document.querySelectorAll('[class*=range-filter-input-]').forEach((el, i) => {

      const from = el.querySelector('[name=from]').value
      const to = el.querySelector('[name=to]').value
      selected.push({
        filter_id: el.id.replace('filter_id_', ''),
        from: from,
        to: to
      })

    })

    this.setState({
      selectedRanges: selected
    })

  }

  filterCheck(opt) {
    let checked = []
    document.querySelectorAll('.filter-check:checked').forEach((el, i) => {
      if (!el.classList.contains('brand-check')) checked.push(el.id.replace('f', ''))
    })
    this.setState({
      selectedFilters: checked
    })
  }

  brandCheck() {
    let checked = []
    document.querySelectorAll('.brand-check:checked').forEach((el, i) => {
      checked.push(el.id.replace('m', ''))
    })
    this.setState({
      selectedManufacturers: checked
    })

  }

  colorCheck() {
    let checked = []
    document.querySelectorAll('.color-check:checked').forEach((el, i) => {
      checked.push(el.id.replace('c', ''))
    })
    this.setState({
      selectedColors: checked
    })
  }

  removeFilter(e) {
    document.getElementById(e.target.getAttribute('data-id')).checked = false
    this.filterCheck()
  }

  changePage(num) {

    this.setState({
      page: num,
      isLoading: true,
      products: []
    })

    this.filterProducts(num)

    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    // setTimeout(() => {
    //   this.filterProducts(num)
    // }, 300)

    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth"
    //   });
    // }, 400)

  }

  resetFilters() {

    localStorage.removeItem(storageProductsChars)

    document.querySelectorAll('.filter-check').forEach((el, i) => {
      el.checked = false
    })
    this.setState({
      isLoading: true,
      selectedRanges: [],
      selectedFilters: [],
      selectedManufacturers: [],
      selectedColors: [],
      price_from: this.state.min,
      price_to: this.state.max
    })
    setTimeout(() => {
      this.changePage(1)
    }, 300)

  }

  saveFiltersToStore(newData) {

    localStorage.removeItem(storageProductsChars)

    localStorage.setItem(storageFilters, JSON.stringify(newData))
  }

  filterProducts(new_page) {

    let pageCurr = new_page ? new_page : this.state.page

    if (pageCurr > 1)
      this.props.history.push('/catalog/' + this.props.match.params.slug + '/' + pageCurr)

    const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

    let postData = {
      'slug': this.props.match.params.slug,
      'page': pageCurr,
      'arrayFilter': this.state.selectedFilters.length === 0 ? '' : this.state.selectedFilters.join(', '),
      'manufacturer': this.state.selectedManufacturers.length === 0 ? 'no' : this.state.selectedManufacturers.join(', '),
      'price_from': this.state.price_from,
      'price_to': this.state.price_to,
      'order_by': document.getElementById('order').value,
      'selectedRanges': this.state.selectedRanges,
      'tc_id': selectedCenterId
    }


    // console.log('filterProducts - postData', postData)

    this.saveFiltersToStore(postData)

    this.setState({
      isLoading: true,
      products: [],
      order_by: postData.order_by
    })
    axios.post(`${process.env.REACT_APP_API_BASE}/pages/category-filter`, postData)
      .then((response) => {
        let respData = response.data.data;
        // console.log('filterProducts response', respData)
        //if (respData.length === 6) respData.unshift([])
        if (respData) {
          // const listWithVariable = respData[0].filter(item => (item?.char || item.color !== null))
          const listWithVariable = respData[0].filter(item => (isIterableArray(item?.char) || isIterableArray(item.color)))
          this.setState({
            isProductsHasVariable: isIterableArray(listWithVariable),
            selectedRanges: this.formatFilterToRanges(respData[1]),
            products: respData[0],
            filters: respData[1],
            manufacturers: respData[2],
            pagination: respData[4],
            selectedFilters: respData[5].filter,
            selectedManufacturers: respData[5].manufacturer[0] === 'no' ? [] : respData[5].manufacturer,
            selectedColors: respData[5].filter,
            order_by: respData[5].orderBy,
            price_from: respData[5].price_from || respData[6].minPrice,
            price_to: respData[5].price_to || respData[6].maxPrice,
            min: respData[6].minPrice,
            max: respData[6].maxPrice,
            colors: respData[3]
          }, () => {

            this.setState({
              isLoading: false
            })
          })
        }
      })
  }

  addSomeUselessTags() {

    if (this.state.page !== 1) {
      document.querySelectorAll('meta[name="robots"]').forEach((el) => {
        document.querySelector('head').removeChild(el)
      })
      let metaR = document.createElement('meta')
      metaR.name = 'robots'
      metaR.content = 'noindex, follow'
      document.querySelector('head').appendChild(metaR)
    } else {
      document.querySelectorAll('meta[name="robots"]').forEach((el) => {
        document.querySelector('head').removeChild(el)
      })
      if (this.state.trueRobots) {
        let metaR = document.createElement('meta')
        metaR.name = 'robots'
        metaR.content = this.state.trueRobots
        document.querySelector('head').appendChild(metaR)
      }
    }
    this.linkiHuinki()
  }

  linkiHuinki() {
    document.querySelectorAll('link[rel="next"], link[rel="prev"]').forEach((el) => {
      document.querySelector('head').removeChild(el)
    })
    if (this.state.pagination.lastPage > 1) {
      if (this.state.page === 1) {
        let l1 = document.createElement('link')
        l1.rel = 'next'
        l1.href = window.location.href + '/2'
        document.querySelector('head').appendChild(l1)
      } else if (this.state.page < this.state.pagination.lastPage) {
        let l1 = document.createElement('link')
        l1.rel = 'next'
        let l2 = document.createElement('link')
        l2.rel = 'prev'
        let q = this.state.pagination.lastPage.toString().length + 1
        l1.href = window.location.href.slice(0, -q) + '/' + (+this.state.page + 1)
        l2.href = window.location.href.slice(0, -q) + (+this.state.page - 1 === 1 ? '' : '/' + (+this.state.page - 1))
        document.querySelector('head').appendChild(l1)
        document.querySelector('head').appendChild(l2)
      } else {
        let l1 = document.createElement('link')
        l1.rel = 'prev'
        let q = this.state.pagination.lastPage.toString().length + 1
        l1.href = window.location.href.slice(0, -q) + (this.state.pagination.lastPage - 1 === 1 ? '' : '/' + (this.state.pagination.lastPage - 1))
        document.querySelector('head').appendChild(l1)
      }

    }
    if (this.state.page !== 1) {
      if (!document.querySelector('link[rel="canonical"]')) {
        let cunt = document.createElement('link')
        cunt.rel = 'canonical'
        let src = window.location.href
        cunt.href = src.slice(0, -2)
        document.head.appendChild(cunt)
      } else {
        let src = window.location.href
        document.querySelector('link[rel="canonical"]').href = src.slice(0, -2)
      }
    } else {
      if (document.querySelector('link[rel="canonical"]')) {
        let c = document.querySelector('link[rel="canonical"]')
        document.head.removeChild(c)
      }
    }
  }

  onLowerBoundChange = (e) => {
    this.setState({ price_from: +e.target.value });
  }
  onUpperBoundChange = (e) => {
    this.setState({ price_to: +e.target.value });
  }
  onSliderChange = (value) => {
    this.setState({
      price_from: value[0],
      price_to: value[1]
    });
  }
  handleApply = () => {
    const { min, max } = this.state;
    this.setState({ value: [min, max] });
  }

  componentDidUpdate(prevProps, prevState) {
    document.querySelector('#menu-underlay.open') && document.querySelector('#menu-underlay.open').click()
    this.addSomeUselessTags()
    // console.log(prevProps.match.params.slug, this.props.match.params.slug);
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.setState({
        page: 1
      }, () => {
        this.fetchData()
      })

    }
  }


  toFormatProductSelected(arr) {
    if (isIterableArray(arr)) {
      return arr.map(item => (
        {
          filtersSelected: item.chars || [],
          product_id: item.product_id
        }
      ))
    }
    else return []
  }
  fetchData() {
    if (this.state.page > 1)
      this.props.history.push('/catalog/' + this.props.match.params.slug + '/' + this.state.page)
    axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-one/${this.props.match.params.slug}`)
      .then((response) => {

        // console.log(response.data.data)
        // if(response.data.data[0].redirect !== null) {
        //     // window.location.reload()
        //     if(response.data.data[0].redirect.redirect_type === 'prod') {
        //         window.location.replace(`/product/${response.data.data[0].redirect.redirect_to}`)
        //     }
        //     if(response.data.data[0].redirect.redirect_type === 'cat') {
        //         // console.log(response.data.data[0])
        //         if(response.data.data[0].redirect.type_cat === 'no_have_cat') {
        //             window.location.replace(`/catalog/${response.data.data[0].redirect.redirect_to}`)
        //         } else {
        //             window.location.replace(`/category/${response.data.data[0].redirect.redirect_to}`)
        //         }
        //     }
        // }

        this.setState({
          banners: response.data.data[1],
          current: response.data.data[0],
          crumbs: response.data.data[2].reverse()
        })

        let metaTitle, metaDescription, robotsIndex, robotsFollow
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.seo_title) {
          metaTitle = response.data.data[0].meta_tags.seo_title
        }
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.meta_discription) {
          metaDescription = response.data.data[0].meta_tags.meta_discription
        }
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.robots_index) {
          robotsIndex = response.data.data[0].meta_tags.robots_index
        }
        if (response.data.data[0].meta_tags && response.data.data[0].meta_tags.robots_follow) {
          robotsFollow = response.data.data[0].meta_tags.robots_follow
        }

        if (metaTitle) document.title = metaTitle;
        else document.title = this.state.current.name + ' | Галактика';
        if (metaDescription) {
          if (!document.querySelector('meta[name="description"]')) {
            let metaD = document.createElement('meta')
            metaD.name = 'description'
            metaD.content = metaDescription
            document.querySelector('head').appendChild(metaD)
          } else {
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
          }
        }
        let metaR = document.createElement('meta')
        metaR.name = 'robots'
        let robotsContent = ''
        if ((robotsIndex || robotsFollow) && this.state.page === 1) {
          if (robotsIndex === 'yes') {
            robotsContent += 'index'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else if (robotsIndex === 'no') {
            robotsContent += 'noindex'
            if (robotsFollow === 'yes') {
              robotsContent += ', follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += ', nofollow'
            }
          } else {
            if (robotsFollow === 'yes') {
              robotsContent += 'follow'
            }
            if (robotsFollow === 'no') {
              robotsContent += 'nofollow'
            }
          }
          if (this.state.page !== 1) {
            robotsContent = 'noindex, follow'
          }
          metaR.content = robotsContent
          this.setState({
            trueRobots: robotsContent
          })
          document.querySelector('head').appendChild(metaR)
        }

      })
      .catch(err => {
        // console.error(err);
        window.location.href = '/404'

      })

    const storageFiltersData = localStorage.getItem(storageFilters) ? JSON.parse(localStorage.getItem(storageFilters)) : {}



    const storageCharsData = localStorage.getItem(storageProductsChars) ? JSON.parse(localStorage.getItem(storageProductsChars)) : []

    // const productsSelectedChar = storageCharsData.filter(item => item.slug === this.props.match.params.slug)
    const productsSelectedChar = storageCharsData


    const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false


    let postData = {
      'productsSelectedChar':
        storageFiltersData.slug === this.props.match.params.slug ?
          storageFiltersData.arrayFilter
            ?
            []
            :
            this.toFormatProductSelected(productsSelectedChar)
          :
          this.toFormatProductSelected(productsSelectedChar)
      ,
      'slug': this.props.match.params.slug,
      'page': this.state.page,
      'arrayFilter': '',
      'manufacturer': 'no',
      'price_from': 0,
      'price_to': 'no',
      'order_by': 1,
      'selectedRanges': '',
    }
    if (storageFiltersData.slug === this.props.match.params.slug) {
      postData = { ...postData, ...storageFiltersData }
    }

    postData.tc_id = selectedCenterId
    // console.log('POST DATA', postData)

    this.setState({
      isLoading: true,
      products: [],
      order_by: postData.order_by
    })
    axios.post(`${process.env.REACT_APP_API_BASE}/pages/category-filter`, postData)
      .then((response) => {

        // console.log('category filter response', response)
        const offsetTop = sessionStorage.getItem(storageProductOffsetTop)

        if (offsetTop) {
          setTimeout(() => {
            window.scrollTo({
              top: offsetTop,
              behavior: 'smooth'
            })
            sessionStorage.removeItem(storageProductOffsetTop)
          }, 300);
        }

        // document.querySelectorAll('.filter-check:checked').forEach((el, i) => {
        //   if (!el.classList.contains('brand-check')) checked.push(el.id)
        // })
        // document.querySelectorAll('.brand-check:checked').forEach((el, i) => {
        //   checked.push(el.id.replace('m', ''))
        // })

        if (response.status === 200) {
          const listWithVariable = response.data.data[0].filter(item => (isIterableArray(item?.char) || isIterableArray(item.color)))
          this.setState({
            isProductsHasVariable: isIterableArray(listWithVariable),
            selectedRanges: this.formatFilterToRanges(response.data.data[1]),
            products: response.data.data[0],
            filters: response.data.data[1],
            manufacturers: response.data.data[2],
            pagination: response.data.data[4],
            selectedFilters: response.data.data[5].filter,
            selectedManufacturers: response.data.data[5].manufacturer[0] === 'no' ? [] : response.data.data[5].manufacturer,
            selectedColors: response.data.data[5].filter,
            order_by: response.data.data[5].orderBy,
            price_from: response.data.data[5].price_from || response.data.data[6].minPrice,
            price_to: response.data.data[5].price_to || response.data.data[6].maxPrice,
            min: response.data.data[6].minPrice,
            max: response.data.data[6].maxPrice,
            colors: response.data.data[3]
          }, () => {

            this.setState({
              isLoading: false
            })
          })
        }

      })

  }

  formatFilterToRanges(filters) {
    return filters.reduce((acc, item) => {
      if (item.type === 'range') {
        acc.push({
          filter_id: item.filter_id,
          from: item.default_from || item.filter_option[0].filter_option_range[0],
          to: item.default_to || item.filter_option[item.filter_option.length - 1].filter_option_range[item.filter_option[item.filter_option.length - 1].filter_option_range.length - 1],
        })
      }
      return acc
    }, [])
  }

  // fetchSlider() {

  //   const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

  //   axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-slider/${this.props.match.params.slug}/${selectedCenterId}`)
  //     .then(response => {
  //       // console.log('catalog slider', response.data)
  //       if (isIterableArray(response?.data?.data)) {
  //         this.setState({
  //           slidersData: response.data.data,
  //         })
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error)
  //     })
  // }
  componentDidMount() {
    this.fetchData();
    // this.fetchSlider();

    // window.addEventListener('mousewheel', this.handleScroll);
  }
  // componentWillUnmount() {
  //   window.removeEventListener('mousewheel', this.handleScroll);
  // }
  // handleScroll(e) {
  //   console.log(e.wheelDelta)
  //   // document.querySelector('.catalog-filter').offsetTop 
  //   const windowInnerHeight = window.innerHeight
  //   const valv = document.querySelector('.catalog-filter').getBoundingClientRect()

  //   const { bottom, top } = valv
  //   console.log(valv)
  //   if (bottom <= windowInnerHeight) {
  //     this.setState({
  //       filtersStyle: {
  //         position: 'sticky',
  //         bottom: 0,
  //         alignSelf: 'flex-end'
  //       }
  //     });
  //   }
  //   if (e.wheelDelta > 0) {
  //     console.log(bottom, windowInnerHeight)
  //     // if (bottom <= windowInnerHeight) {
  //     //   this.setState({
  //     //     filtersStyle: {
  //     //       position: 'sticky',
  //     //       bottom: 0,
  //     //       alignSelf: 'flex-end'
  //     //     }
  //     //   });
  //     // }
  //     // else {
  //     // this.setState({
  //     //   filtersStyle: {}
  //     // });
  //     // }
  //   }
  //   // else {
  //   //   // this.setState({
  //   //   //   filtersStyle: {
  //   //   //     position: 'sticky',
  //   //   //     top: 0,
  //   //   //     alignSelf: 'flex-start'
  //   //   //   }
  //   //   // });
  //   //   console.log('scroll to top')
  //   // }
  // }

  handleClick() {
    document.body.classList.toggle('bfix');
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  toggleFilter(e) {
    this.setState(prevState => ({
      filterToggleOn: !prevState.filterToggleOn
    }));
    let parent;
    if (e.target.type === 'button') parent = e.target.parentNode
    else parent = e.target.parentNode.parentNode
    parent.classList.toggle('collapsed')
  }

  // getFilterName(id) {
  //   let arr = this.state.filters;
  //   let name = '';
  //   arr.map((f, i) => {
  //     f.filter_option.map((o, j) => {
  //       if (o.filter_option_id === id) name = o.filter_option_value
  //       return null
  //     })
  //     return null
  //   })
  //   return name;
  // }

  changePageAndHandle() {
    this.handleClick();
    this.changePage(1);
  }

  setSelectedRanges(value) {
    this.setState({
      selectedRanges: value
    })
  }


  static contextType = CartCtx;

  render() {
    return (
      <main className='page-category'>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item href={process.env.REACT_APP_CLIENT}><i className="i-home"></i></Breadcrumb.Item>
            {this.state.crumbs.length > 0 &&
              this.state.crumbs.map((c, i) => {
                return (
                  <React.Fragment key={i}>
                    {c.redirect === null &&
                      <li className={'breadcrumb-item'} key={i}>
                        {this.state.crumbs.length - 1 === i &&
                          <span>
                            {c.name}
                          </span>
                        }
                        {this.state.crumbs.length - 1 !== i &&
                          <Link to={`/${i + 1 === this.state.crumbs.length ? 'catalog' : 'category'}/${c.name_slug}`}>
                            {c.name}
                          </Link>
                        }
                      </li>
                    }
                  </React.Fragment>
                )
              })
            }
          </Breadcrumb>
          <h1>{this.state.current.name}</h1>
          {this.state.current.short_description &&
            <ReadMore
              initialHeight={50}
              readMore={props => (
                <div className='readmore__button' onClick={props.onClick}>
                  {props.open ? '' : 'Читать дальше'}
                </div>
              )}
            >
              <div className="readmore__caption" dangerouslySetInnerHTML={{ __html: this.state.current.short_description }}>
              </div>
            </ReadMore>
          }
          {this.state.banners.length > 0 &&
            <ImageSlider items={this.state.banners} />
          }

          <Catalog
            state={this.state}

            handleClick={this.handleClick}
            resetFilters={this.resetFilters}
            filterProducts={this.filterProducts}
            changePageAndHandle={this.changePageAndHandle}

            toggleFilter={this.toggleFilter}
            brandCheck={this.brandCheck}
            onLowerBoundChange={this.onLowerBoundChange}
            onUpperBoundChange={this.onUpperBoundChange}
            onSliderChange={this.onSliderChange}
            filterCheck={this.filterCheck}
            rangeCheck={this.rangeCheck}
            setSelectedRanges={this.setSelectedRanges}

            changePage={this.changePage}

            context={this.context}
          />


          <SliderFetchWrapper
            urlFetch={`${process.env.REACT_APP_API_BASE}/pages/category-slider/${this.props.match.params.slug}`}
          />

          <div className="cat-desc">
            <div className="cat-desc__wrap" dangerouslySetInnerHTML={{ __html: this.state.current.bottom_description }}>
            </div>
          </div>

        </Container>
      </main>
    );
  }
}

export default PageCategoryChild;
