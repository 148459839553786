import React, { useState, useEffect, useRef } from "react";
import "@aarsteinmedia/dotlottie-player";
import { Link } from "react-router-dom";
import { getSelectedProduct, isIterableArray, pathToLottieAnimation } from "../utils";
// import Badges from "../main/Badges";
import Timer from "../Timer";
import Badges from "../../main/Badges";

import fontColorContrast from 'font-color-contrast'
import moment from 'moment'

const BlockItem7 = ({ url_list, data }) => {

  // const [selectedProduct, setSelectedProduct] = useState(getSelectedProduct(data.product));

  const animationBg = selectedProduct?.animation ? selectedProduct.animation.background : 0; // 0  


  // const selectedProductCallback = useCallback(
  //   () => {
  //     console.log('callback wrk!')
  //     return getSelectedProduct(data.product)
  //   },
  //   [data.product],
  // )

  // const selectedProduct = selectedProductCallback()
  // const selectedProduct = getSelectedProduct(data.product)
  const selectedProduct = data.product[0]


  // console.log(data.product, selectedProductCallback())


  //   useEffect(() => {
  //     if (selectedProduct === null) {
  //       console.log('sel prod', selectedProduct)
  //       setSelectedProduct(getSelectedProduct(data.product))
  //     }
  //   }, [data.product]);

  // console.log(selectedProduct)

  const playerRef = useRef(null)
  const [animationURL, setAnimationURL] = useState(null);

  useEffect(() => {
    if (selectedProduct?.animation) handleLottieAnimationLoad()
  }, [selectedProduct?.animation]);

  const handleLottieAnimationLoad = () => {
    // const lottieFileURL = selectedProduct?.animation ? `/lottie/${data.animation.url}` : null;
    const lottieFileURL = selectedProduct?.animation ? `${pathToLottieAnimation}${selectedProduct.animation.url}` : null;
    setAnimationURL(lottieFileURL);
  }

  useEffect(() => {
    if (playerRef.current) {
      waitForLottieAnimationLoaded().catch((err) => {
        console.log('waitForLottieAnimationLoaded', err)
        setAnimationURL(null)
      })
    }
  }, [playerRef.current]);

  const waitForLottieAnimationLoaded = () => {
    return new Promise(() => {
      if (!playerRef?.current?.getLottie()) {
        throw new Error('animation error');
      }
    });
  }




  return (
    <li className={`glide__slide slider block type-${data.type}`}>
      <div className={`block7-animation  ${animationBg === 0 ? "fg" : "bg"}`}>
        {
          selectedProduct?.animation && animationURL !== null &&
          <dotlottie-player
            ref={playerRef}
            src={animationURL}
            background="transparent"
            speed={selectedProduct.animation.speed}
            direction="1"
            mode="normal"
            loop
            autoplay
          ></dotlottie-player>
        }
      </div>
      {
        selectedProduct &&
        <Link
          to={selectedProduct.link_button ? selectedProduct.link_button.replace("https://galaktika.me", "") : '#'}
        >
          <div className="block7-container">
            <div className="block7-content">
              <div className="left">
                {
                  isIterableArray(selectedProduct.priceBadgeStock?.bages) &&
                  <Badges items={selectedProduct.priceBadgeStock} />
                }
                {/* <div className="badge">Товар дня</div> */}
                {
                  selectedProduct.photo_url &&
                  <img
                    className="product-img small-block__img"
                    src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + selectedProduct.photo_url}
                    alt={''}
                  />
                }
              </div>
              <div className="right">
                {/* <h3>{selectedProduct.articule}</h3> */}
                <h3>{selectedProduct.name}</h3>
                <div className="attrs d-none d-md-flex mt-3">
                  {
                    isIterableArray(selectedProduct.character) &&
                    selectedProduct.character.map((item, index) => (
                      <div key={index} className="attrs-item">
                        <div>{item.nameChar}</div>
                        -
                        <div>{item.valueChar}</div>
                      </div>
                    ))
                  }
                </div>
                {
                  selectedProduct.motive_text &&
                  <h5 className="motive-text my-3">{selectedProduct.motive_text}</h5>
                }
                <div className="price-container d-sm-none">
                  {
                    selectedProduct?.priceBadgeStock?.price?.discountPrice &&
                    <div className="price__old">
                      <span className="price i-rub d-inline-block">
                        {
                          selectedProduct.priceBadgeStock?.price?.price?.toLocaleString("ru")
                        }
                      </span>
                    </div>
                  }
                  <span className="price i-rub">
                    {
                      selectedProduct?.priceBadgeStock?.price?.discountPrice &&
                      selectedProduct.priceBadgeStock.price.discountPrice.toLocaleString("ru")
                    }
                    {
                      !selectedProduct?.priceBadgeStock?.price?.discountPrice &&
                      selectedProduct.priceBadgeStock?.price?.price &&
                      selectedProduct.priceBadgeStock?.price?.price?.toLocaleString("ru")
                    }
                    {/* {selectedProduct.priceBadgeStock?.price?.price?.toLocaleString("ru")} */}
                  </span>
                  {
                    selectedProduct.unit &&
                    <div className='price-unit' style={{ fontSize: 20 }}>
                      <span>
                        /
                      </span>
                      <span>
                        {selectedProduct.unit}
                      </span>
                    </div>
                  }
                </div>

              </div>
            </div>

            <div className="block7-flex">
              <div className="timer-container">
                <h6>{selectedProduct.stock_text}</h6>

                <Timer dateEndStock={selectedProduct?.date_end_stock} />

              </div>

              <div className="price-container">

                <div className="d-none d-sm-flex align-items-start flex-column">
                  {
                    selectedProduct?.priceBadgeStock?.price?.discountPrice &&
                    <div className="price__old">
                      <span className="price i-rub d-inline-block">
                        {
                          selectedProduct.priceBadgeStock?.price?.price?.toLocaleString("ru")
                        }
                      </span>
                    </div>
                  }
                  <span className="price i-rub">
                    {
                      selectedProduct?.priceBadgeStock?.price?.discountPrice &&
                      selectedProduct.priceBadgeStock.price.discountPrice.toLocaleString("ru")
                    }
                    {
                      !selectedProduct?.priceBadgeStock?.price?.discountPrice &&
                      selectedProduct.priceBadgeStock?.price?.price &&
                      selectedProduct.priceBadgeStock?.price?.price?.toLocaleString("ru")
                    }
                  </span>

                  {
                    selectedProduct.unit &&
                    <div className='price-unit' style={{ fontSize: 20 }}>
                      <span>
                        /
                      </span>
                      <span>
                        {selectedProduct.unit}
                      </span>
                    </div>
                  }
                </div>
                <div
                  // to={selectedProduct.link_button ? selectedProduct.link_button.replace("https://galaktika.me", "") : '#'}
                  className="btn"
                  style={{
                    backgroundColor: selectedProduct.color_button,
                    color: fontColorContrast(selectedProduct.color_button),
                  }}
                >
                  {selectedProduct.text_button}
                </div>
              </div>
            </div>
          </div>
        </Link>
      }
    </li>
  );
};

export default BlockItem7;
