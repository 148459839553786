import axios from 'axios'
import fontColorContrast from 'font-color-contrast'
import React, { useState, useEffect } from 'react'
import { Col, Form, Row, Spinner, Alert } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import ReactInputMask from 'react-input-mask'
import { isIterableArray } from '../../block-slider/utils'

import queryString from 'query-string';

const Type9 = ({ data }) => {
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isValid },
    } = useForm()

    const { text_on_success, text_button, text_under, button_color, stock_item_id } = data.setting

    const receiptFromParams = queryString.parse(window.location.search).receipt

    const [isLoading, setIsLoading] = useState(false); // загрузка при отправке
    const [isAlertSuccess, setIsAlertSuccess] = useState(false);
    const [isAlertError, setIsAlertError] = useState(false);
    const [textOnError, setTextOnError] = useState('');

    const [isValidReceipt, setIsValidReceipt] = useState(true);

    const [itemsData, setItemsData] = useState({
        checkboxs: [],
        inputs: [],
    });

    useEffect(() => {
        if (data) {
            dataToFormat(data.items)
        }
    }, [data]);



    useEffect(() => {
        if (receiptFromParams) {
            if (checkIsValidReceipt(receiptFromParams)) {
                setIsValidReceipt(true)
                setValue('userCheck', receiptFromParams)
            }
            else {
                setIsValidReceipt(false)
            }
        }
    }, [setValue, receiptFromParams]);


    const checkIsValidReceipt = (rec) => {
        // 0ENR 01 0612 0000 130 
        // 0ENR010612000013041
        const kassa = Number(rec.slice(4, 6))
        const smena = Number(rec.slice(6, 10))
        const check = Number(rec.slice(10, 17))
        const check_result = Number(rec.slice(17, rec.length))

        let checksum = ((kassa * 9 + check) * 3 + (smena * 2)) % 100

        if (checksum < 10) {
            checksum = "0" + checksum
        }
        if (checksum === check_result) return true
        else return false
    }

    // форматируем данные. делим поля на чекбоксы, текстовые инпуты и кнопки 
    // каждому инпуту задаю fieldName который потом используется для формирования данных для отправки
    const dataToFormat = (data) => {
        const checkboxItems = data.reduce((acc, item, index) => {
            if (item.type === 1) acc.push({
                ...item,
                fieldName: `checkbox_${index}`,
            })
            return acc
        }, [])
        const inputItems = data.reduce((acc, item, index) => {
            if (item.type > 1 && item.type < 7) acc.push({
                ...item,
                fieldName: `input_${index}`,
            })
            if (item.type === 9) acc.push({
                ...item,
                fieldName: `userCheck`,
            })
            return acc
        }, [])
        setItemsData({
            checkboxs: checkboxItems,
            inputs: inputItems,
        })

    }

    const onSubmit = (formData) => {
        const formatter = (arr) => {
            return arr.reduce((acc, item) => {
                const val = {
                    forEmail: item.forEmail,
                    value: formData[item.fieldName]
                }
                acc.push(val)
                return acc
            }, [])
        }

        const formattedCheckboxs = formatter(itemsData.checkboxs)
        const formattedInputs = formatter(itemsData.inputs)

        const sendData = {
            checkboxs: formattedCheckboxs,
            inputs: formattedInputs,
            stock_item_id: stock_item_id
        }

        handleSend(sendData)
    }


    const handleSend = (sendData) => {
        setIsLoading(true)

        axios
            .post(`${process.env.REACT_APP_API_BASE}/pages/send-gift-member`, sendData)
            .then((response) => {
                if (response?.data && response.data?.res) {
                    if (response.data.res === 'Success')
                        setIsAlertSuccess(true)
                    if (response.data.res === 'Error') {
                        setIsAlertError(true)
                        setTextOnError(response.data.msg || 'Попробуйте повторить позже')
                    }
                }
                // reset()
            })
            .catch((err) => {
                console.log(err)
                setIsAlertError(true)
                setTextOnError('Что-то пошло не так')
            })
            .finally(() => {
                setIsLoading(false)
            });
    }



    const RenderFormItems = ({ items }) => (
        items.map((item, key) => {
            switch (item.type) {
                case 1: return <CheckboxItem key={key} item={item} />
                case 2: return TextCol(item)
                case 3: return TextCol(item)
                case 4: return PhoneCol(item)

                case 9: return UserCheckCol(item)

                default: return '';
            }
        })
    )

    const CheckboxItem = ({ item }) => (
        <Col className='mb-2'>
            <Form.Check
                type="checkbox"
                label={item.text}
                id={item.fieldName}
                {...register(item.fieldName, { required: !!item.required })}
            />
            {
                errors[item.fieldName] &&
                <Form.Control.Feedback type="invalid" className='d-block'>
                    Обязательно к заполнению
                </Form.Control.Feedback>
            }
        </Col>
    )
    const UserCheckCol = (item) => (
        <Col md={6} className='mb-2' key={item.fieldName}>
            <Form.Control
                readOnly={true}
                placeholder={item.text}
                type="text"
                {...register(item.fieldName, { required: !!item.required })}
            />
        </Col>
    )
    const TextCol = (item) => (
        <Col md={item.type === 2 ? 6 : 12} className='mb-2' key={item.fieldName}>
            <Form.Control
                placeholder={item.text}
                type="text"
                {...register(item.fieldName, { required: !!item.required })}
            />
            {
                errors[item.fieldName] &&
                <Form.Control.Feedback type="invalid" className='d-block'>
                    Обязательно к заполнению
                </Form.Control.Feedback>
            }
        </Col>
    )
    const PhoneCol = (item) => (
        <Col md={6} className='mb-2' key={item.fieldName}>
            <Form.Control
                // as={ReactInputMask}
                // mask="+7(999)-999-9999"
                type="tel"
                placeholder={item.text || 'Телефон'}
                defaultValue={'+7'}
                {...register(item.fieldName, { required: true })}
            // {...register(item.fieldName, { required: true, pattern: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g })}
            />
            {
                errors[item.fieldName]?.type === 'pattern' &&
                <Form.Control.Feedback type="invalid" className='d-block'>
                    Телефон заполнен некорректно
                </Form.Control.Feedback>
            }
        </Col>
    )

    if (!receiptFromParams) return <></>

    return (
        <div className='buildedPage-type_8' >

            {data.title && <div className='title' dangerouslySetInnerHTML={{ __html: data.title }} />}


            <form onSubmit={handleSubmit(onSubmit)}>

                <div className='buildedPage-type_8-form'>
                    {
                        isAlertSuccess &&
                        <Alert variant={'success'} onClose={() => setIsAlertSuccess(false)} dismissible>
                            <div dangerouslySetInnerHTML={{ __html: text_on_success }} />
                        </Alert>
                    }
                    {
                        isAlertError &&
                        <Alert variant={'danger'} onClose={() => {
                            setIsAlertError(false)
                            setTextOnError('')
                        }} dismissible>
                            <Alert.Heading style={{ fontSize: '1.15rem' }}>Ошибка при отправке</Alert.Heading>
                            <p className='mb-0'>{textOnError}</p>
                        </Alert>
                    }
                    {
                        !isValidReceipt &&
                        <Alert variant={'danger'}  >
                            <Alert.Heading style={{ fontSize: '1.15rem' }}>Неправильный чек</Alert.Heading>
                            <p className='mb-0'>{'Проверьте правильность чека'}</p>
                        </Alert>
                    }
                    <Row>
                        {
                            isIterableArray(itemsData.inputs) &&
                            <RenderFormItems items={itemsData.inputs} />
                        }
                    </Row>

                    <Row className='mt-3'>
                        <Col md={6} lg={7} xxl={8}>
                            {
                                isIterableArray(itemsData.checkboxs) &&
                                <RenderFormItems items={itemsData.checkboxs} />
                            }
                        </Col>
                        <Col md={6} lg={5} xxl={4}>
                            <button
                                disabled={!isValid || !isValidReceipt}
                                className='btn btn-submit'
                                type='submit'
                                style={{
                                    backgroundColor: button_color,
                                    color: fontColorContrast(button_color)
                                }}
                            >
                                {
                                    isLoading ?
                                        <Spinner animation='border' />
                                        :
                                        <div dangerouslySetInnerHTML={{ __html: text_button }}></div>
                                }
                            </button>

                            {
                                text_under &&
                                <div className='text_under mt-3' dangerouslySetInnerHTML={{ __html: text_under }} />
                            }
                        </Col>
                    </Row>
                </div>

            </form>
        </div >
    )
}

export default Type9
