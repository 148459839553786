import React, { useContext } from 'react'
import { Accordion, AccordionContext, Nav, useAccordionToggle } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import iconDiscount from '../../../img/slider-discount.svg'
import { isIterableArray } from '../../block-slider/utils'
import { getIsFollow, HeaderTopMenuLink, MenuImages } from './linkUtils'

const FooterMenuMobile = ({ headerData, footerData, FormSubscribe }) => {


    function CustomToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <div
                onClick={decoratedOnClick}
                className={`menu-item__btn ${isCurrentEventKey ? "" : 'rotated'}`}
            >
                {children}
            </div>
        );
    }



    return (

        <div className='footer-mobile d-md-none'>
            <div className='footer-mobile__content'>

                <FormSubscribe />
                {

                    footerData?.stock &&
                    <Accordion>
                        <div className="menu-item" >
                            <div className='pt'>
                                <CustomToggle eventKey={`sale`}>
                                    {
                                        footerData.stock?.item?.name
                                    }
                                    <div className='arrow-icon'><i className="i-arrow next" /></div>
                                </CustomToggle>
                            </div>

                            <Accordion.Collapse eventKey={`sale`}>

                                <div className="menu-item__wrap">
                                    <div className="menu-item__inner">
                                        {
                                            isIterableArray(footerData.stock.subItems) && footerData.stock.subItems.map(item => (
                                                <Link
                                                    key={item.id}
                                                    {...getIsFollow(item.link?.follow)}
                                                    target={item.link.notGalaxy ? "_blank" : '_self'}
                                                    to={{ pathname: item.link.url }}
                                                >
                                                    {
                                                        item.icon &&
                                                        <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                                                    }
                                                    {item.name}
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                }
                {
                    isIterableArray(footerData?.items) &&
                    footerData.items.map((item, key) => (

                        // активный ключ только для первых двух элементов 
                        isIterableArray(item.subItems) ?
                            <Accordion key={item.id} className={key > 0 ? 'nokey' : `${key}`} >
                                <div className="menu-item" >
                                    <div className="pt" >
                                        <CustomToggle eventKey={`${key}`}>
                                            {
                                                item.icon &&
                                                <img className="header-top-menu__icon" src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} />
                                            }
                                            <span dangerouslySetInnerHTML={{ __html: item.name }} />
                                            <div className='arrow-icon'><i className="i-arrow next" /></div>
                                        </CustomToggle>
                                    </div>

                                    <Accordion.Collapse eventKey={`${key}`}>
                                        <div className="menu-item__wrap">
                                            <div className="menu-item__inner">
                                                {
                                                    isIterableArray(item.subItems) && item.subItems.map(item => (
                                                        <HeaderTopMenuLink key={item.id} itemLink={item} />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                            :
                            <div className="menu-item" key={item.id} >
                                <div className="menu-item__btn">
                                    <HeaderTopMenuLink itemLink={item} />
                                </div>
                            </div>
                    ))
                }
                <div className='pt-items'>
                    {
                        isIterableArray(footerData?.type2) &&
                        <MenuImages items={footerData?.type2} />
                    }
                </div>

                <div className="social">
                    <p>
                        {
                            footerData?.type8?.text_socials
                        }
                    </p>
                    <div className="social-list">
                        {
                            isIterableArray(footerData?.type8?.socials) &&
                            footerData.type8.socials.map((item, key) => (
                                <div className="social-list__item" key={key}>
                                    <Nav.Link href={item.url} target="_blank">
                                        <img src={process.env.REACT_APP_BACKEND + '/uploads/pages/menu/' + item.icon} alt={item.alt} />
                                    </Nav.Link>
                                </div>
                            ))
                        }
                    </div>
                </div>



                <div className="menu-item mt-4">
                    <div className="menu-item__btn" dangerouslySetInnerHTML={{ __html: headerData?.contact?.name }} />
                    <div className="menu-item__inner">
                        {
                            isIterableArray(headerData?.contact?.subItems) && headerData.contact.subItems.map(item => (
                                item?.phones?.length > 3 ?
                                    <div key={item.id} className='menu-contact-item'>
                                        <span>{item.name}</span>
                                        {' — '}
                                        <a href={`tel:${item.phones}`}>
                                            {item.phones}
                                        </a>
                                    </div>
                                    :
                                    <span key={item.id} />
                            ))
                        }
                    </div>
                </div>

            </div>
        </div >
    )
}

export default FooterMenuMobile
