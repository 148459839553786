//React
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Breadcrumb, Tabs, Tab, Modal, Button, Nav } from 'react-bootstrap';
import ImageZoom from './ImageZoom';
import SocialShare from './SocialShare';
import Countdown, { zeroPad } from 'react-countdown-now';
import axios from 'axios';
import "react-image-gallery/styles/scss/image-gallery.scss";

import SliderProductsLastView from '../main/SliderProductLastView';
import Badges from "../main/Badges";
import WishList from "../main/ModalWishlist";

//SCSS
import "../../scss/components/pages/singleProduct/imageGallery.scss"
import "../../scss/components/pages/singleProduct/singleProduct.scss"

import CartCtx from '../CartCtx'
import { isIterableArray } from '../block-slider/utils';

import ProductColorChar from '../categories/character/product/ProductColorChar'
import ProductChartList from '../categories/character/product/ProductChartList'
import { getChars, saveToStorage } from '../categories/ProductCard';
import { storageProductsChars } from '../categories/utils';
import SingeProductPhotosMobile from './SingeProductPhotosMobile';
import SingeProductPhotosDesktop from './SingeProductPhotosDesktop';
import SliderFetchWrapper from '../block-slider/SliderFetchWrapper';

const Completionist = () => <p className="countdown__end">Акция завершена.</p>;
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a complete state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <span className=""><b>{days} дней</b> <b>{zeroPad(hours)}</b>:<b>{zeroPad(minutes)}</b>:<b>{zeroPad(seconds)}</b></span>
    );
  }
};

class SingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingChars: true,
      // product: def,
      product: {},
      crumbs: {},
      images: [],
      forBlocks: [],
      // slidersData: [],
      selectedChar: {},
      infoCharModal: false
    }



    this.openInfoModal = this.openInfoModal.bind(this)
    this.hideInfoModal = this.hideInfoModal.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.handleSelectChar = this.handleSelectChar.bind(this)
  }

  handleClose() {
    this.setState({
      show: false
    })
  }

  handleShow() {
    this.setState({
      show: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) this.fetchData()
  }

  componentDidMount() {
    const redirectedFromQR = this.props.location.search.indexOf('utm_source=market') !== -1
    // console.log(redirectedFromQR);
    this.fetchData(redirectedFromQR)
    // this.fetchSlider()

  }

  addToCart() {

  }


  handleSelectChar(new_v) {


    this.setState({
      isLoadingChars: true
    })

    const selectedDefaultChar = getChars(this.state.product.char)
    const selectedDefaultColor = getChars(this.state.product.color)


    const filtersSelected = [...selectedDefaultChar, ...selectedDefaultColor]

    // const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

    let postData = {
      filterSelectedLast: new_v,
      filtersSelected: filtersSelected,
      product_id: this.state.product.id,
      // tc_id: selectedCenterId
    };



    axios.post(`${process.env.REACT_APP_API_BASE}/products/product-filter`, postData)
      .then((response) => {
        // console.log(response.status, response)
        if (response.status === 200) {
          if (response.data.data) {

            if (response.data.data?.plus_url) {
              this.props.history.replace({
                search: response.data.data.plus_url
              });
            }

            const selectedDefaultChar = getChars(response.data.data.char)
            const selectedDefaultColor = getChars(response.data.data.color)


            const filtersSelected = [...selectedDefaultChar, ...selectedDefaultColor]

            const canonicalLink = document.querySelector('link[rel=canonical]')
            if (canonicalLink) {
              canonicalLink.href = `https://galaktika.me/product/${response.data.data.canonical}`
            }
            else {
              const charset = document.querySelector('meta[charset]')
              let canonical = document.createElement('link')
              canonical.rel = 'canonical'
              canonical.href = `https://galaktika.me/product/${response.data.data.canonical}`
              charset.after(canonical)
            }


            saveToStorage(filtersSelected, this.state.product.id, response.data.data.id, response.data.data.name_slug, response.data.data.category_id)


            this.setState({
              product: response.data.data
            }, function () {

              let imgArr = []

              if (this.state.product.prodPhoto.length > 0) {
                this.state.product.prodPhoto.map((img, i) => {
                  imgArr.push({
                    type: img.includes('mp4') ? 'video' : 'photo',
                    original: process.env.REACT_APP_BACKEND + img.replace('/main/', '/main_origin/'),
                    thumbnail: process.env.REACT_APP_BACKEND + img.replace('/main/', '/main_origin/')
                  })

                  return false
                })
                this.setState({
                  images: imgArr
                })
              } else {
                imgArr.push({
                  type: this.state.product.photo_url.includes('mp4') ? 'video' : 'photo',
                  original: process.env.REACT_APP_BACKEND + 'uploads/products/main_origin/' + this.state.product.photo_url,
                  thumbnail: process.env.REACT_APP_BACKEND + 'uploads/products/main/' + this.state.product.photo_url
                })
                this.setState({
                  images: imgArr
                })
              }
            })
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.setState({
          isLoadingChars: false
        })

      })

  }
  fetchDataWithChars() {

    const product_slug = this.props.match.params.slug

    const storageCharsData = localStorage.getItem(storageProductsChars) ? JSON.parse(localStorage.getItem(storageProductsChars)) : []

    const productsSelectedChar = storageCharsData.filter(item => item.product_slug === product_slug)

    // console.log('fetch_with_chars', productsSelectedChar)

    if (isIterableArray(productsSelectedChar)) {

      // const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

      let postData = {
        productsSelectedChar: {
          filtersSelected: productsSelectedChar[0].chars,
          product_id: this.state.product.id
        },
        // tc_id: selectedCenterId
      }

      // console.log('fetch with chars ready', productsSelectedChar[0].chars)

      axios.post(`${process.env.REACT_APP_API_BASE}/products/product-slug-filter`, postData)
        .then((response) => {
          // console.log(response.status, response)


          if (response.status === 200) {
            if (response.data.data) {



              const canonicalLink = document.querySelector('link[rel=canonical]')
              if (canonicalLink) {
                canonicalLink.href = `https://galaktika.me/product/${response.data.data.canonical}`
              }
              else {
                const charset = document.querySelector('meta[charset]')
                let canonical = document.createElement('link')
                canonical.rel = 'canonical'
                canonical.href = `https://galaktika.me/product/${response.data.data.canonical}`
                charset.after(canonical)
              }

              // this.props.history.replace({
              //   search: response.data.data.plus_url
              // });

              this.setState({
                product: response.data.data
              })
            }
          }
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.setState({
            isLoadingChars: false
          })
        })
    }
    else {
      this.setState({
        isLoadingChars: false
      })
    }
  }

  fetchData(redirectedFromQR) {

    // const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

    axios.get(`${process.env.REACT_APP_API_BASE}/products/slug/${this.props.match.params.slug}`)
      .then((response) => {

        // console.log('fetch data response', response.data.data)

        if (response.data.data.redirect !== null) window.location.href = '/404'
        this.setState({
          product: response.data.data
        }, function () {

          this.fetchDataWithChars()
          // this.setSelectedCharsCharAfterFetch(response.data.data)

          // console.log(this.state.product);
          if (localStorage.getItem('galaxy-token')) {
            axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/viewed-list/add-product`, {
              jwt: localStorage.getItem('galaxy-token'),
              newProduct: this.state.product.id
            }).then(response => {

            }).catch(err => {
              console.error(err)
            })
          }
        })

        // if (response.data.data.name_slug === response.data.data.canonical) {
        //   this.props.history.replace({
        //     search: ''
        //   });
        // }
        // else {
        //   this.props.history.replace({
        //     search: response.data.data.plus_url
        //   });
        // }

        const charset = document.querySelector('meta[charset]')

        const canonicalLink = document.querySelector('link[rel=canonical]')
        if (response?.data?.data?.canonical) {
          if (canonicalLink) {
            canonicalLink.href = `https://galaktika.me/product/${response.data.data.canonical}`
          }
          else {
            const charset = document.querySelector('meta[charset]')
            let canonical = document.createElement('link')
            canonical.rel = 'canonical'
            canonical.href = `https://galaktika.me/product/${response.data.data.canonical}`
            charset.after(canonical)
          }
        }

        let robotsContent = ''
        let metaD = document.createElement('meta')
        metaD.name = 'robots'

        if (response.data.data.meta_tags) {
          let metaTitle = response.data.data.meta_tags.seo_title || null
          let metaDescription = response.data.data.meta_tags.meta_discription || null
          let robotsIndex = response.data.data.meta_tags.robots_index || null
          let robotsFollow = response.data.data.meta_tags.robots_follow || null

          if (metaTitle) document.title = metaTitle;
          else document.title = this.state.product.name + ' | Галактика';
          if (metaDescription) {
            if (!document.querySelector('meta[name="description"]')) {
              let metaD = document.createElement('meta')
              metaD.name = 'description'
              metaD.content = metaDescription
              charset.after(metaD)
            } else {
              document.querySelector('meta[name="description"]').setAttribute('content', metaDescription)
            }
          }
          document.querySelectorAll('meta[name="robots"]').forEach((el) => {
            document.querySelector('head').removeChild(el)
          })
          if (redirectedFromQR) {
            robotsContent = "noindex, nofollow"
          } else if (robotsIndex || robotsFollow) {
            if (robotsIndex === 'yes' && robotsFollow === 'yes') {
              robotsContent += 'index, follow'
            }
            if (robotsIndex === 'yes' && robotsFollow === 'no') {
              robotsContent += 'index, nofollow'
            }
            if (robotsIndex === 'no' && robotsFollow === 'yes') {
              robotsContent += 'noindex, follow'
            }
            if (robotsIndex === 'no' && robotsFollow === 'no') {
              robotsContent += 'noindex, nofollow'
            }
          }
        } else if (redirectedFromQR) {
          robotsContent = "noindex, nofollow"
        }
        metaD.content = robotsContent ? robotsContent : 'index, follow'
        charset.after(metaD)

        axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-page/${this.state.product.category.name_slug}/1`)
          .then(response => {
            this.setState({
              crumbs: response.data.data[4].reverse()
            })
            axios.get(`${process.env.REACT_APP_API_BASE}/pages/category-page/${this.state.crumbs[this.state.crumbs.length - 2].name_slug}/1`)
              .then(response => {
                this.setState({
                  forBlocks: response.data.data[0]
                })
              })
              .catch(error => {
                console.error(error)
              })

          })
          .catch(error => {
            console.error(error)
          })

        let imgArr = []
        // console.log(this.state.product)
        if (this.state.product.prodPhoto.length > 0) {
          this.state.product.prodPhoto.map((img, i) => {
            if (img.includes('mp4')) {
              imgArr.push({
                type: 'video',
                original: process.env.REACT_APP_BACKEND + img.replace('/main/', '/main_origin/'),
                thumbnail: process.env.REACT_APP_BACKEND + img.replace('/main/', '/main_origin/')
              })
            }
            else {
              imgArr.push({
                type: 'photo',
                original: process.env.REACT_APP_BACKEND + img.replace('/main/', '/main_origin/'),
                thumbnail: process.env.REACT_APP_BACKEND + img.replace('/main/', '/main_origin/')
              })
            }

            return false
          })
          this.setState({
            images: imgArr
          })
        } else {
          imgArr.push({
            original: process.env.REACT_APP_BACKEND + 'uploads/products/main_origin/' + this.state.product.photo_url,
            thumbnail: process.env.REACT_APP_BACKEND + 'uploads/products/main/' + this.state.product.photo_url
          })
          this.setState({
            images: imgArr
          })
        }


        //document.title = this.state.product.name + ' | Галактика';
      })
      .catch((error) => {
        console.error(error);
        // window.location.href = '/404'
      })
  }
  // fetchSlider() {
  //   const selectedCenterId = this.context.selectedCenter ? this.context.selectedCenter.id : false

  //   axios.get(`${process.env.REACT_APP_API_BASE}/pages/product-slider/${this.props.match.params.slug}/${selectedCenterId}`)
  //     .then(response => {
  //       if (isIterableArray(response?.data?.data)) {
  //         // console.log('single product slider response', response.data.data)
  //         this.setState({
  //           slidersData: response.data.data,
  //         })
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error)
  //     })
  // }

  hideInfoModal() {
    this.setState({
      infoCharModal: false
    })
  }

  openInfoModal(data) {
    this.setState({
      infoCharModal: {
        title: data.title,
        body: data.value
      }
    })
  }


  static contextType = CartCtx;


  render() {

    let selectedPrice, selectedStatus

    if (this.context.selectedCenter?.id) {
      if (isIterableArray(this.state.product.tc)) {
        selectedPrice = this.state.product.tc.find(item => item.id == this.context.selectedCenter.id)
        // selectedStatus = isIterableArray(this.state.product.status) ? this.state.product.status.find(item => item.tc_id == this.context.selectedCenter.id) : false

      }
    }
    // else {
    //   selectedStatus = isIterableArray(this.state.product.status) ? this.state.product.status.find(item => item.defaultTc === true) : false
    // }


    const productPrice = selectedPrice ? {
      price: selectedPrice.price,
      priceBadgeStock: selectedPrice.priceBadgeStock
    } : {
      price: this.state.product.price,
      priceBadgeStock: this.state.product.priceBadgeStock
    }

    // const productStatus = selectedStatus


    return (
      <>
        <main className='page-product'>
          {
            this.state.infoCharModal ?
              <Modal
                show={!!this.state.infoCharModal}
                onHide={this.hideInfoModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="modal-xl"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <div dangerouslySetInnerHTML={{ __html: this.state.infoCharModal.title }} />
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div dangerouslySetInnerHTML={{ __html: this.state.infoCharModal.body }} />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="link"
                    onClick={this.hideInfoModal}
                    className="btn-secondary"
                  >
                    Закрыть
                  </Button>
                </Modal.Footer>
              </Modal>
              :
              ''
          }
          <Container>
            <Breadcrumb>
              <li className={'breadcrumb-item'}>
                <Link to={`/`}>
                  <i className="i-home" />
                </Link>
              </li>
              {this.state.crumbs.length > 0 &&
                this.state.crumbs.map((c, i) => {
                  return (
                    <React.Fragment key={i}>
                      {c.redirect === null &&
                        <li className={'breadcrumb-item'}>
                          <Link to={`/${i + 1 === this.state.crumbs.length ? 'catalog' : 'category'}/${c.name_slug}`}>
                            {c.name}
                          </Link>
                        </li>
                      }
                    </React.Fragment>
                  )
                })
              }
              <li className={'breadcrumb-item'}>
                <span>
                  {this.state.product?.name}
                </span>
              </li>
            </Breadcrumb>
            <h1>{this.state.product?.name}</h1>
            <div className="product-header">
              <p className="product-header__code">Код: <span>{this.state.product?.articule}</span></p>
            </div>

            {this.state.product?.id &&
              <div className="single-product">
                <Tabs defaultActiveKey="main" transition={false} id="singleProduct1">
                  <Tab eventKey="main" title="Всё о товаре">

                    <div className="product-main">
                      <div className="product-main__left">
                        <div className="product-main__gallery">

                          {
                            this.state.images.length > 0 &&
                            <div className='d-lg-none'>
                              <SingeProductPhotosMobile
                                gift={this.state.product.gift}
                                images={this.state.images}
                                name={this.state.product.name}
                                badges={productPrice.priceBadgeStock}
                              />
                            </div>
                          }
                          {
                            this.state.images.length > 0 &&
                            <div className='d-none d-lg-block'>
                              <SingeProductPhotosDesktop
                                gift={this.state.product.gift}
                                images={this.state.images}
                                name={this.state.product.name}
                                badges={productPrice.priceBadgeStock}
                              />
                            </div>
                          }
                          {/* {
                            this.state.images.length > 0 &&
                            <ImageZoom images={this.state.images} name={this.state.product.name} />
                          } */}
                        </div>
                        {
                          this.state.product.text && this.state.product.text.text3 &&
                          <div className={`mt-3 ${this.state.images.length > 0 ? 'mt-sm-80' : ''}`} dangerouslySetInnerHTML={{ __html: this.state.product.text.text3 }} />
                        }
                      </div>
                      <div className="product-main__info">
                        <div className='product-main__info__chars'>
                          <ProductColorChar
                            isLoadingCard={this.state.isLoadingChars}
                            character={this.state.product.color}
                            handleSelectChar={this.handleSelectChar}
                          />
                          <ProductChartList
                            isLoadingCard={this.state.isLoadingChars}
                            handleSelectChar={this.handleSelectChar}
                            character={this.state.product.char}
                            openInfoModal={this.openInfoModal}
                          />
                        </div>
                        {/* <div className="product-stock">
                          <div className="products-stock">
                            {
                              this.state.product.priceBadgeStock.bages.length > 0 &&
                              <Badges items={this.state.product.priceBadgeStock} />
                            }
                          </div>
                        </div> */}
                        {
                          this.state.product.stock_item_id &&
                          <div className="product-promo">
                            <div className=""
                              style={{
                                maxWidth: '45%',
                                marginRight: 15
                              }}>
                              {this.state.product.stock.image_url ?
                                <img
                                  src={`${process.env.REACT_APP_BACKEND}uploads/sp/stocks/${this.state.product.stock.image_url}`}
                                  alt=""
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: 70,
                                    display: 'block'
                                  }}
                                /> :
                                <img src="https://pngimage.net/wp-content/uploads/2018/06/sale-sign-png-.png"
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: 70,
                                    display: 'block'
                                  }} alt="" />
                              }
                            </div>

                            <div className="product-promo__content" style={{ marginLeft: 0 }}>
                              <h5>
                                <Link to={`/promo/${this.state.product.stock.name_slug}`}
                                  style={{
                                    color: 'inherit'
                                  }}
                                >
                                  Акция! {this.state.product.stock.name}
                                </Link>
                              </h5>
                              {this.state.product.stock.show_day !== 0 &&
                                (
                                  <p>Завершается через:<br />
                                    <Countdown
                                      date={this.state.product.stock.date_end}
                                      renderer={renderer}
                                    />
                                  </p>)}
                            </div>

                            {this.state.product.stock.text_for_modal &&
                              this.state.product.stock.button &&
                              (<div className="product-promo__detail">
                                <Button variant="primary" onClick={this.handleShow}>
                                  {this.state.product.stock.button}
                                </Button>
                                <Modal size="lg" show={this.state.show}
                                  onHide={this.handleClose}>
                                  <Modal.Header closeButton>
                                    <Modal.Title>Условия акции</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div className="modal-scroll"
                                      dangerouslySetInnerHTML={{ __html: this.state.product.stock.text_for_modal }} />
                                  </Modal.Body>
                                  {/*<Modal.Footer>*/}
                                  {/*  <Button variant="secondary"*/}
                                  {/*          onClick={this.handleClose}>Закрыть</Button>*/}
                                  {/*</Modal.Footer>*/}
                                </Modal>
                              </div>)}
                          </div>
                        }
                        {this.state.product.haveSameProdArray.length > 0 &&
                          <div className="product-variations">
                            <div className="variation">
                              <p className="variation__title">Цвет:</p>
                              <div className="variation__inner">
                                <div className="products-colors">
                                  <div className="products-colors__wrap">
                                    <Link to='#' className="products-colors__item active">
                                      <span style={{
                                        background: this.state.product.color.color_code_RGBA,
                                        border: '1px solid #546'
                                      }} />
                                      <p>{this.state.product.color.name}</p>
                                    </Link>
                                    {this.state.product.haveSameProdArray.map((pr, i) => {

                                      return (
                                        <Link to='#' className="products-colors__item active" key={i}>
                                          <span style={{ background: pr.color__code_RGBA, border: '1px solid #546' }} />
                                          <p>{pr.color_name}</p>
                                        </Link>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        <div className="product-trade">
                          <div className="products-price product-sale">
                            <div className="products-price__wrap">
                              {
                                productPrice.priceBadgeStock.price.discountPrice &&
                                <div className="products-price__old">
                                  <span className="price i-rub">
                                    {productPrice.priceBadgeStock.price.price.toLocaleString('ru')}
                                  </span>

                                </div>
                              }
                              <div className="products-price__main">
                                {productPrice.priceBadgeStock.price.discountPrice &&
                                  <span className="price i-rub">
                                    {productPrice.priceBadgeStock.price.discountPrice.toLocaleString('ru')}
                                  </span>
                                }
                                {!productPrice.priceBadgeStock.price.discountPrice &&
                                  <span className="price i-rub" style={{ color: '#333' }}>
                                    {productPrice.priceBadgeStock.price.price.toLocaleString('ru')}
                                  </span>
                                }
                              </div>

                              {
                                this.state.product.unit &&
                                <div className='price-unit' style={
                                  productPrice.priceBadgeStock.price.discountPrice ?
                                    {
                                      order: 3,
                                      fontSize: 20,
                                      color: 'rgba(10, 26, 92, 0.4)',
                                      lineHeight: 1.4
                                    }
                                    :
                                    {
                                      lineHeight: 1.4
                                    }
                                }>
                                  <span>
                                    /
                                  </span>
                                  <span>
                                    {this.state.product.unit}
                                  </span>
                                </div>
                              }
                            </div>
                          </div>
                          {
                            this.state.product.text && this.state.product.text.text6 &&
                            <div className='mt-5 my-3' dangerouslySetInnerHTML={{ __html: this.state.product.text.text6 }} />
                          }

                          {
                            // productStatus ?
                            //   <div className='d-flex align-items-center my-4' style={{ gap: 6 }}>
                            //     {
                            //       productStatus.icon ?
                            //         <img style={{ width: 20 }} src={`${process.env.REACT_APP_BACKEND}/site_image/status_icons/${productStatus.icon}`} alt='' />
                            //         :
                            //         ''
                            //     }
                            //     <div dangerouslySetInnerHTML={{ __html: productStatus.name_card }} />

                            //   </div>
                            //   :
                            //   ''
                          }
                          <div className="product-stock">
                            <div className="products-stock">
                              {
                                isIterableArray(this.state.product.count_center_status) &&
                                this.state.product.count_center_status.map(item => (
                                  <div key={item.center_id} className="products-stock__item">
                                    <b>
                                      {item.name}
                                      <span>
                                        {item.address}
                                      </span>
                                    </b>
                                    {
                                      item.status ?
                                        <div className='d-flex align-items-center justify-content-end' style={{ gap: 4 }}>
                                          {
                                            item.status.icon ?
                                              <img style={{ width: 15 }} src={`${process.env.REACT_APP_BACKEND}/site_image/status_icons/${item.status.icon}`} alt='' />
                                              :
                                              ''
                                          }
                                          <div dangerouslySetInnerHTML={{ __html: item.status.name_card }} />
                                        </div>
                                        :
                                        <>
                                          {
                                            item.count_tc > 0 &&
                                            <p className="stock-in">Товар есть в наличии</p>
                                          }
                                          {
                                            item.count_tc === 0 &&
                                            <p className="stock-out">Товара нет в наличии</p>
                                          }
                                        </>
                                    }
                                  </div>
                                ))
                              }
                              {/*{process.env.REACT_APP_TEST === `1` &&*/}
                              {/*    <>*/}
                              {/*        {this.state.product.count > 0 &&*/}
                              {/*            <p className="stock-in">Товар есть в наличии</p>*/}
                              {/*        }*/}
                              {/*        {this.state.product.count === 0 &&*/}
                              {/*            <p className="stock-out">Товара нет в наличии</p>*/}
                              {/*        }*/}
                              {/*    </>*/}
                              {/*}*/}
                              {/* <>
                                <div className="products-stock__item">
                                  <b>TЦ №1 <span>г. Макеевка, просп. 250-летия Донбасса, 74</span></b>
                                  {this.state.product.count_1 > 0 &&
                                    <p className="stock-in">Товар есть в наличии</p>
                                  }
                                  {this.state.product.count_1 === 0 &&
                                    <p className="stock-out">Товара нет в наличии</p>
                                  }
                                </div>
                                <div className="products-stock__item">
                                  <b>TЦ №2 <span>г. Донецк, ул. Шутова, 37</span></b>
                                  {this.state.product.count_2 > 0 &&
                                    <p className="stock-in">Товар есть в наличии</p>
                                  }
                                  {this.state.product.count_2 === 0 &&
                                    <p className="stock-out">Товара нет в наличии</p>
                                  }
                                </div>
                                <div className="products-stock__item">
                                  <b>TЦ №3 <span>г. Горловка, ул. Маршала Жукова, 7</span></b>
                                  {this.state.product.count_3 > 0 &&
                                    <p className="stock-in">Товар есть в наличии</p>
                                  }
                                  {this.state.product.count_3 === 0 &&
                                    <p className="stock-out">Товара нет в наличии</p>
                                  }
                                </div>
                                <div className="products-stock__item">
                                  <b>TЦ №4 <span>г. Донецк ЖД, ул. Соколиная, 38</span></b>
                                  {this.state.product.count_4 > 0 &&
                                    <p className="stock-in">Товар есть в наличии</p>
                                  }
                                  {this.state.product.count_4 === 0 &&
                                    <p className="stock-out">Товара нет в наличии</p>
                                  }
                                </div>
                                <div className="products-stock__item">
                                  <b>TЦ №5 <span>г. Енакиево, пр-т Металлургов, 65А</span></b>
                                  {this.state.product.count_5 > 0 &&
                                    <p className="stock-in">Товар есть в наличии</p>
                                  }
                                  {this.state.product.count_5 === 0 &&
                                    <p className="stock-out">Товара нет в наличии</p>
                                  }
                                </div>
                                <div className="products-stock__item">
                                  <b>TЦ №6 <span>г. Донецк, Петровский р-н, ул. Добровольского, 4</span></b>
                                  {this.state.product.count_6 > 0 &&
                                    <p className="stock-in">Товар есть в наличии</p>
                                  }
                                  {this.state.product.count_6 === 0 &&
                                    <p className="stock-out">Товара нет в наличии</p>
                                  }
                                </div>
                              </> */}
                            </div>
                          </div>

                          {
                            this.state.product.text && this.state.product.text.text1 &&
                            <div className='my-2' dangerouslySetInnerHTML={{ __html: this.state.product.text.text1 }} />
                          }

                          {(this.state.product.count_1 > 0 || this.state.product.count_2 > 0 || this.state.product.count_3 > 0 || this.state.product.count_4 > 0) &&
                            <div className="cart-block">
                              <div className="cart-btn">
                                {!this.context.isInCart(this.state.product.id) &&
                                  <CartCtx.Consumer>
                                    {({ cart, addProduct }) => (
                                      <Button className="add-to-cart" onClick={() => addProduct(this.state.product.id)}><i
                                        className="i-cart"></i>Добавить в корзину</Button>
                                    )}
                                  </CartCtx.Consumer>
                                }
                                {this.context.isInCart(this.state.product.id) &&
                                  <Button className="add-to-cart added" disabled>
                                    <i className="i-check"></i>В корзине
                                  </Button>
                                }
                              </div>
                              <div className="btns-dop">
                                <WishList productId={this.state.product.id} productCount={this.state.product.count} />
                              </div>
                            </div>
                          }
                        </div>
                        <div className="product-shortdesc pt-0 pb-3">
                          <div>
                            {this.state.product.short_discription &&
                              <div dangerouslySetInnerHTML={{ __html: this.state.product.short_discription }}></div>
                            }
                            <br />
                            {this.state.product.character.map((ch, i) => {
                              return (
                                <span key={i}>
                                  {ch.nameChar}: {ch.valueChar}
                                  {
                                    i < this.state.product.character.length - 1 &&
                                    <>&nbsp;/&nbsp;</>
                                  }
                                </span>
                              )
                            })}
                          </div>
                        </div>

                        {
                          this.state.product.social &&
                          <div className="product-subscribe">
                            <p className="subscribe__title" dangerouslySetInnerHTML={{ __html: this.state.product.social?.text }} />
                            <div className="subscribe__wrap social-list">
                              {
                                isIterableArray(this.state.product.social.arr) &&
                                this.state.product.social.arr.map((item, key) => (
                                  <div className="social-list__item mr-3" key={key}>
                                    <Nav.Link href={item.url} target="_blank">
                                      <img src={process.env.REACT_APP_BACKEND + '/uploads/settings/socials/' + item.icon} alt={item.alt} />
                                    </Nav.Link>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        }

                        {/* <SocialShare url={process.env.REACT_APP_CLIENT + this.props.location.pathname} /> */}
                      </div>
                    </div>
                    <div className="product-main__description">
                      <div className="product-main__left">
                        <div className="product-desc">
                          <h2 className="block-title">Описание <span>{this.state.product.name}</span></h2>
                          <div className="product-desc__wrap">
                            {this.state.product.full_discription &&
                              <div dangerouslySetInnerHTML={{ __html: this.state.product.full_discription }}></div>
                            }
                          </div>
                        </div>
                        {
                          this.state.product.text && this.state.product.text.text4 &&
                          <div className='mt-3' dangerouslySetInnerHTML={{ __html: this.state.product.text.text4 }} />
                        }
                      </div>
                      <div className="product-main__right">
                        {
                          (isIterableArray(this.state.product.character_by_groups) || isIterableArray(this.state.product.character)) &&
                          <div className="product-specs">
                            <h2 className="block-title">Характеристики <span>{this.state.product.name}</span></h2>
                            <div className="product-specs__wrap">
                              <div className="product-specs__inner">
                                {
                                  isIterableArray(this.state.product.character_by_groups) ?
                                    <div className="product-shortdesc">
                                      {
                                        this.state.product.character_by_groups.map((char_item, key) => (
                                          <div key={key} className='character_by_groups'>
                                            {char_item.title && <h3 className='character_by_groups__title'>{char_item.title}</h3>}
                                            <div >
                                              {
                                                isIterableArray(char_item.value_arr) &&
                                                char_item.value_arr.map((ch, i) => {
                                                  return (
                                                    <div className="product-specs__item" key={i}>
                                                      <p>{ch.nameChar}</p>
                                                      <span className="dotted"></span>
                                                      <p>{ch.valueChar}</p>
                                                    </div>
                                                  )
                                                })
                                              }
                                            </div>
                                          </div>
                                        ))
                                      }
                                    </div>
                                    :
                                    isIterableArray(this.state.product.character) ?
                                      <div className="product-shortdesc">
                                        {this.state.product.character.map((ch, i) => {
                                          return (
                                            <div className="product-specs__item" key={i}>
                                              <p>{ch.nameChar}</p>
                                              <span className="dotted"></span>
                                              <p>{ch.valueChar}</p>
                                            </div>
                                          )
                                        })}
                                      </div>
                                      :
                                      ''
                                }
                              </div>
                              {/* <div className="product-main__footer">
                                <div className="product-warning">
                                  <p>* Характеристики и комплектация товара могут изменяться производителем без
                                    уведомления</p>
                                </div>
                              </div> */}
                              <div className="more-info">
                                {/* <Button variant="link">Смотреть все характеристики</Button> */}
                              </div>
                            </div>
                          </div>
                        }

                        {
                          this.state.product.text && this.state.product.text.text2 &&
                          <div className='mt-3' dangerouslySetInnerHTML={{ __html: this.state.product.text.text2 }} />
                        }
                      </div>
                    </div>
                    <div className="product-main__productSlider">
                      {this.state.forBlocks.length > 0 &&
                        this.state.forBlocks.map((block, i) => {
                          return (
                            <SliderProductsLastView items={block} key={i} />
                          )
                        })

                      }
                    </div>
                  </Tab>
                  {
                    ((isIterableArray(this.state.product.character_by_groups) || isIterableArray(this.state.product.character))) &&
                    <Tab eventKey="specs" title="Характеристики">
                      <div className="product-specs tab">
                        <div className="product-specs__wrap ">
                          <h2 className="block-title">Характеристики <span>{this.state.product.name}</span></h2>
                          <div className="product-specs__section">
                            {
                              isIterableArray(this.state.product.character_by_groups) ?
                                <div className="product-shortdesc">
                                  {
                                    this.state.product.character_by_groups.map((char_item, key) => (
                                      <div key={key} className='character_by_groups'>
                                        {char_item.title && <h3 className='character_by_groups__title'>{char_item.title}</h3>}
                                        <div >
                                          {
                                            isIterableArray(char_item.value_arr) &&
                                            char_item.value_arr.map((ch, i) => {
                                              return (
                                                <div className="product-specs__item" key={i}>
                                                  <p>{ch.nameChar}</p>
                                                  <span className="dotted"></span>
                                                  <p>{ch.valueChar}</p>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    ))
                                  }
                                </div>
                                :
                                isIterableArray(this.state.product.character) ?
                                  <div className="product-shortdesc">
                                    {this.state.product.character.map((ch, i) => {
                                      return (
                                        <div className="product-specs__item" key={i}>
                                          <p>{ch.nameChar}</p>
                                          <span className="dotted"></span>
                                          <p>{ch.valueChar}</p>
                                        </div>
                                      )
                                    })}
                                  </div>
                                  :
                                  ""
                            }
                            {/* {this.state.product.color &&
                              <div className="product-shortdesc">
                                <div className="product-specs__item">
                                  <p>Цвет</p>
                                  <span className="dotted"></span>
                                  <p>
                                    {this.state.product.color.name}
                                  </p>
                                </div>
                              </div>
                            } */}
                            {this.state.product.manufacturer &&
                              <div className="product-shortdesc">
                                <div className="product-specs__item">
                                  <p>Производитель</p>
                                  <span className="dotted"></span>
                                  <p>
                                    {this.state.product.manufacturer.name}
                                  </p>
                                </div>
                              </div>
                            }
                            {
                              this.state.product.text && this.state.product.text.text5 &&
                              <div className='mt-3' dangerouslySetInnerHTML={{ __html: this.state.product.text.text5 }} />
                            }
                          </div>
                        </div>
                      </div>
                    </Tab>
                  }
                  {/*this.state.product.prodPhoto.length > 0 &&
                                        <Tab eventKey="photo" title="Фото">
                                            <div className="product-photos">
                                                <h2 className="block-title">Фотографии <span>{this.state.product.name}</span></h2>
                                                <div className="product-photos__wrap">
                                                    {
                                                        this.state.product.prodPhoto.map((img, i) => {
                                                            return (
                                                                <img src={process.env.REACT_APP_BACKEND + img.photo_url} alt="" key={i} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                    */}
                </Tabs>
              </div>
            }

            <SliderFetchWrapper
              urlFetch={`${process.env.REACT_APP_API_BASE}/pages/product-slider/${this.props.match.params.slug}`}
            />

          </Container>
        </main>
      </>
    );
  }
}

export default SingleProduct;
